<template>
  <v-badge
    avatar
    bordered
    overlap
    bottom
    color="primary"
    :icon="icon"
    :value="icon"
  >
    <v-avatar :size="size">
      <v-img :src="src" />
    </v-avatar>
  </v-badge>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      require: true,
    },

    type: {
      type: String,
      default: undefined,
    },

    size: {
      type: Number,
      default: 50,
    },
  },

  computed: {
    icon() {
      let icon = "";

      switch (this.type) {
        case "admin":
          icon = "mdi-crown";
          break;
        case "guard":
          icon = "mdi-shield";
          break;
        case "manager":
          icon = "mdi-account-group";
          break;
        case "guest":
          icon = "mdi-clock";
          break;
        case "employee":
          icon = "mdi-briefcase";
          break;
        default:
          break;
      }

      return icon;
    },
  },
};
</script>
