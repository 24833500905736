var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('date-picker',{attrs:{"type":"datetime","range":"","format":_vm.DATE_FORMAT,"value-type":"format","placeholder":_vm.$ml.get('advanced.dateTime'),"confirm":"","clearable":false,"disabled-date":_vm.notBeforeAndAfter100Days},on:{"confirm":_vm.submit,"pick":_vm.onPickDate,"close":_vm.onCloseDatepicker},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("advanced.range100days"))+" ")]},proxy:true}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","bordered":"","overlap":"","content":_vm.identityTypes.length,"value":_vm.identityTypes.length ? true : false}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("advanced.identityTypes"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.submit},model:{value:(_vm.identityTypes),callback:function ($$v) {_vm.identityTypes=$$v},expression:"identityTypes"}},[_c('v-list-item',{attrs:{"value":"qr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.qr")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"card"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.card")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"face"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.face")))])],1)]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","bordered":"","overlap":"","content":_vm.userType.length,"value":_vm.userType.length ? true : false}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("users.userType"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.submit},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[_c('v-list-item',{attrs:{"value":"employee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.employee")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"guest"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.guest")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.manager")))])],1)]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","bordered":"","overlap":"","content":_vm.selectionSubdivisions.length,"value":_vm.selectionSubdivisions.length ? true : false}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("users.subdivision"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}])},[_c('v-card',{attrs:{"max-height":"400","max-width":"500"}},[_c('v-text-field',{attrs:{"label":_vm.$ml.get('datatable.search'),"flat":"","solo":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.searchSubdivisions),callback:function ($$v) {_vm.searchSubdivisions=$$v},expression:"searchSubdivisions"}}),_c('v-divider',{staticClass:"m-0"}),_c('v-list',[_c('v-treeview',{attrs:{"selectable":"","items":_vm.subdivisions,"item-text":"name","selection-type":"all","search":_vm.searchSubdivisions,"open-all":_vm.searchSubdivisions ? true : false},on:{"input":_vm.submit},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return (item.is_guest)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-account-clock")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("subdiv.guest")))])])]:undefined}}],null,true),model:{value:(_vm.selectionSubdivisions),callback:function ($$v) {_vm.selectionSubdivisions=$$v},expression:"selectionSubdivisions"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","max-width":400,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","bordered":"","overlap":"","content":_vm.selectionDepartments.length,"value":_vm.selectionDepartments.length ? true : false}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("users.department"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}])},[_c('v-card',{attrs:{"max-height":"400","max-width":"500"}},[_c('v-text-field',{attrs:{"label":_vm.$ml.get('datatable.search'),"flat":"","solo":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.searchDepartments),callback:function ($$v) {_vm.searchDepartments=$$v},expression:"searchDepartments"}}),_c('v-divider',{staticClass:"m-0"}),_c('v-list',[_c('v-treeview',{attrs:{"selectable":"","items":_vm.departments,"item-text":"name","selection-type":"independent","search":_vm.searchDepartments,"open-all":_vm.searchDepartments ? true : false},on:{"input":_vm.submit},model:{value:(_vm.selectionDepartments),callback:function ($$v) {_vm.selectionDepartments=$$v},expression:"selectionDepartments"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":_vm.$ml.get('menu.advanced'),"hide-details":""},on:{"change":_vm.submit},model:{value:(_vm.isOnObject),callback:function ($$v) {_vm.isOnObject=$$v},expression:"isOnObject"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }