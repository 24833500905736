var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"auto","lg":2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","md":12}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","link":""},on:{"click":_vm.goToFullscreen}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-monitor-screenshot ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.monitoringFullScreen")))])])],1),_c('v-col',{attrs:{"cols":"auto","md":12}},[_c('div',{staticClass:"view-switcher"},[_c('v-btn-toggle',{model:{value:(_vm.viewType),callback:function ($$v) {_vm.viewType=$$v},expression:"viewType"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-card-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.combinedMode")))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-card-multiple-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.separateMode")))])])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":12,"md":12,"lg":10}},[(_vm.viewType === 0)?_c('div',{staticClass:"user-card-wrapper"},[(_vm.lastVisit)?_c('div',{class:[
          'user-card',
          'user-card--once',
          {
            'user-card--alarm': _vm.isTempAlarm(_vm.lastVisit.temperatureValue),
          } ]},[_c('div',{staticClass:"column image-column",style:({ backgroundImage: ("url(" + (_vm.lastVisit.photo) + ")") })}),_c('div',{staticClass:"user-card__content"},[(_vm.lastVisit.blocked)?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('button',{staticClass:"btn btn-danger unblock",on:{"click":function($event){return _vm.guestUnblock(_vm.lastVisit)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("users.unblock"))+" ")])]):_vm._e(),(
              !_vm.lastVisit.user_id &&
              _vm.lastVisit.identity_types &&
              _vm.lastVisit.identity_types.includes('face')
            )?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('button',{staticClass:"btn btn-danger unblock",on:{"click":function($event){return _vm.guestReg(_vm.lastVisit)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("users.guestPass"))+" ")])]):_vm._e(),_c('div',{staticClass:"mb-2 text-center"},[(
                _vm.lastVisit.purposeType === 'entrance' ||
                _vm.lastVisit.purposeType === 'duplex'
              )?_c('span',{staticClass:"mr-1 ml-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-enter")])]}}],null,false,1850835931)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])])],1):_vm._e(),(_vm.lastVisit.purposeType === 'exit')?_c('span',{staticClass:"mr-1 ml-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rotate-180",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-exit")])]}}],null,false,700632389)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])])],1):_vm._e(),(_vm.lastVisit.eventType !== 'deviceAlarm')?_vm._l((_vm.lastVisit.identity_types),function(type){return _c('span',{key:type,staticClass:"mr-2 ml-2"},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]):_vm._e()],1)}):_vm._e()],2),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.lastVisit.full_name))]),(_vm.lastVisit.type_user)?_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.$ml.get("users." + _vm.lastVisit.type_user))+" "),(_vm.lastVisit.temperature && !_vm.temperatureRise)?_c('span',{style:(_vm.isTempAlarm(_vm.lastVisit.temperatureValue)
                  ? 'color: red; font-size: 22px; font-weight: 600;'
                  : 'font-size: 22px; color: green;')},[_vm._v(_vm._s(_vm.lastVisit.temperature.replace("/", "")))]):_vm._e()]):_vm._e(),_vm._l((_vm.lastVisit.organization),function(org){return _c('div',{key:org.name,staticClass:"title-company"},[_vm._v(" "+_vm._s(org.name)+" ")])}),(_vm.lastVisit.info)?_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.lastVisit.info)+" ")]):_vm._e()],2)]):_vm._e()]):_vm._e(),(_vm.viewType === 1)?_c('div',{staticClass:"user-card-wrapper"},[(_vm.entrance)?_c('div',{class:[
          'user-card',
          {
            'user-card--alarm': _vm.isTempAlarm(_vm.entrance.temperatureValue),
          } ]},[(_vm.entrance.purpose)?_c('div',{staticClass:"user-card__badge"},[_c('span',{staticClass:"user-card__badge-title"},[_vm._v(" "+_vm._s(_vm.$ml.get("device.purpose_entrance"))+" ")]),_vm._v(" ("+_vm._s(_vm.entrance.purpose)+") ")]):_vm._e(),_c('div',{staticClass:"column image-column",style:({ backgroundImage: ("url(" + (_vm.entrance.photo) + ")") })}),_c('div',{staticClass:"user-card__content"},[(_vm.entrance.blocked)?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('button',{staticClass:"btn btn-danger unblock",on:{"click":function($event){return _vm.guestUnblock(_vm.entrance)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("users.unblock"))+" ")])]):_vm._e(),(
              !_vm.entrance.user_id &&
              _vm.entrance.identity_types &&
              _vm.entrance.identity_types.includes('face')
            )?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('button',{staticClass:"btn btn-danger unblock",on:{"click":function($event){return _vm.guestReg(_vm.entrance)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("users.guestPass"))+" ")])]):_vm._e(),_c('div',{staticClass:"mb-2 text-center"},[_c('span',{staticClass:"mr-1 ml-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-enter")])]}}],null,false,1850835931)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])])],1),(_vm.entrance.eventType !== 'deviceAlarm')?_vm._l((_vm.entrance.identity_types),function(type){return _c('span',{key:type,staticClass:"mr-1 ml-1"},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]):_vm._e()],1)}):_vm._e()],2),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.entrance.full_name))]),(_vm.entrance.type_user)?_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.$ml.get("users." + _vm.entrance.type_user))+" "),(_vm.entrance.temperature && !_vm.temperatureRise)?_c('span',{style:(_vm.isTempAlarm(_vm.entrance.temperatureValue)
                  ? 'color: red; font-size: 22px; font-weight: 600;'
                  : 'font-size: 22px; color: green;')},[_vm._v(_vm._s(_vm.entrance.temperature.replace("/", "")))]):_vm._e()]):_vm._e(),_vm._l((_vm.entrance.organization),function(org){return _c('div',{key:org.name,staticClass:"title-company"},[_vm._v(" "+_vm._s(org.name)+" ")])}),(_vm.entrance.info)?_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.entrance.info)+" ")]):_vm._e(),_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.entrance.visits)+" ")])],2)]):_vm._e(),(_vm.exit)?_c('div',{class:[
          'user-card',
          {
            'user-card--alarm': _vm.isTempAlarm(_vm.exit.temperatureValue),
          } ]},[(_vm.exit.purpose)?_c('div',{staticClass:"user-card__badge"},[_c('span',{staticClass:"user-card__badge-title"},[_vm._v(" "+_vm._s(_vm.$ml.get("device.purpose_exit"))+" ")]),_vm._v(" ("+_vm._s(_vm.exit.purpose)+") ")]):_vm._e(),_c('div',{staticClass:"column image-column",style:({ backgroundImage: ("url(" + (_vm.exit.photo) + ")") })}),_c('div',{staticClass:"user-card__content"},[(_vm.exit.blocked)?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('button',{staticClass:"btn btn-danger unblock",on:{"click":function($event){return _vm.guestUnblock(_vm.exit)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("users.unblock"))+" ")])]):_vm._e(),(
              !_vm.exit.user_id &&
              _vm.exit.identity_types &&
              _vm.exit.identity_types.includes('face')
            )?_c('div',{staticStyle:{"text-align":"center","margin-bottom":"10px"}},[_c('button',{staticClass:"btn btn-danger unblock",on:{"click":function($event){return _vm.guestReg(_vm.exit)}}},[_vm._v(" "+_vm._s(_vm.$ml.get("users.guestPass"))+" ")])]):_vm._e(),_c('div',{staticClass:"mb-2 text-center"},[_c('span',{staticClass:"mr-1 ml-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rotate-180",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-exit")])]}}],null,false,700632389)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])])],1),(_vm.exit.eventType !== 'deviceAlarm')?_vm._l((_vm.exit.identity_types),function(type){return _c('span',{key:type,staticClass:"mr-1 ml-1"},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]):_vm._e()],1)}):_vm._e()],2),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.exit.full_name))]),(_vm.exit.type_user)?_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.$ml.get("users." + _vm.exit.type_user))+" "),(_vm.exit.temperature && !_vm.temperatureRise)?_c('span',{style:(_vm.isTempAlarm(_vm.exit.temperatureValue)
                  ? 'color: red; font-size: 22px; font-weight: 600;'
                  : 'font-size: 22px; color: green;')},[_vm._v(_vm._s(_vm.exit.temperature.replace("/", "")))]):_vm._e()]):_vm._e(),_vm._l((_vm.exit.organization),function(org){return _c('div',{key:org.name,staticClass:"title-company"},[_vm._v(" "+_vm._s(org.name)+" ")])}),(_vm.exit.info)?_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.exit.info)+" ")]):_vm._e(),_c('div',{staticClass:"title-small"},[_vm._v(" "+_vm._s(_vm.exit.visits)+" ")])],2)]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }