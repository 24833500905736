<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ $ml.get("card.accessCode") }} {{ getTypeCode() }}</span
        >
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-select
            v-if="id == 0 && cardtypes.length > 1"
            v-model="cardtype"
            :items="cardtypes"
            item-text="name"
            item-value="val"
            :label="$ml.get('card.type')"
            outlined
            :disabled="isCardReaderMode || disabled"
          />
          <v-row v-if="cardtype == 'card'">
            <v-col cols="6">
              <v-text-field
                :label="$ml.get('card.series')"
                type="number"
                v-model="series"
                :disabled="isCardReaderMode || disabled"
                @input="calcSerialAndNumber"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$ml.get('card.number')"
                type="number"
                v-model="number"
                :disabled="isCardReaderMode || disabled"
                @input="calcSerialAndNumber"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-if="cardtype == 'card'"
            :label="$ml.get('card.hex_code') + ' ORION'"
            type="text"
            v-model="hex_code_bolid"
            :disabled="isCardReaderMode || disabled"
            @input="calcHexBolid"
          />
          <v-text-field
            v-if="cardtype == 'card'"
            :label="$ml.get('card.hex_code')"
            type="text"
            v-model="hex_code"
            :disabled="isCardReaderMode || disabled"
            :rules="hexRules"
            autofocus
            @input="inputHex"
          />
          <v-text-field
            :label="$ml.get('card.fullNumber')"
            type="text"
            v-model="full_number"
            :disabled="isCardReaderMode || disabled"
            @input="calcFullNumber"
            @keyup.enter="store"
          />

          <v-switch
            v-if="!disabled"
            v-model="isCardReaderMode"
            :label="$ml.get('card.cardReaderMode')"
            :disabled="!devices.length"
            :hint="!devices.length ? $ml.get('card.noCardReaderDevices') : ''"
            persistent-hint
          />

          <template v-if="isCardReaderMode">
            <v-select
              v-model="selectedDevice"
              :items="devices"
              :item-text="getItemText"
              item-value="id"
              :label="$ml.get('card.cardReaderDevice')"
              outlined
            />
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
        <v-btn
          v-if="full_number && !disabled"
          color="primary"
          text
          @click="store"
          >{{ $ml.get("button.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import crc8 from "@/plugins/crc8.js";

export default {
  props: {
    value: {
      type: Object,
      require: true,
    },

    isAddQR: {
      type: Boolean,
      default: true,
    },

    isAddCard: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let cardtype = "card";

    if (!this.isAddCard) {
      cardtype = "qr";
    }

    const cardtypes = [];

    if (this.isAddCard) {
      cardtypes.push({ name: "CARD", val: "card" });
    }

    if (this.isAddQR) {
      cardtypes.push({ name: "QR-CODE", val: "qr" });
    }

    return {
      valid: true,

      devices: [],
      selectedDevice: null,
      isCardReaderMode: false,

      id: 0,
      dialog: true,
      name: "",
      series: "",
      number: "",
      full_number: "",
      hex_code: "",
      hex_code_bolid: "",
      expire_date: "",
      active: true,
      cardtype,
      cardtypes,
      hexRules: [
        (v) => {
          if (this.hex_code) {
            return this.validateHex(v) || this.$ml.get("message.isHex");
          }

          return true;
        },
      ],
    };
  },
  methods: {
    validateHex(hex) {
      var re = /^[A-Za-z0-9]+$/;
      return re.test(hex);
    },

    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (!this.$refs.form.validate()) return;

      if (this.full_number) {
        let name = this.full_number;
        if (this.full_number.length > 6) {
          name = this.full_number.substr(this.full_number.length - 6, 6);
        }
        this.value.name = this.cardtype + "-" + name;
        this.value.number = this.full_number;
        this.value.type_card = this.cardtype;
        this.$emit("toggle", false);
      }
    },
    getTypeCode() {
      let _this = this;
      let result = this.cardtype;
      this.cardtypes.forEach((v) => {
        if (v.val == _this.cardtype) {
          result = v.name;
          return;
        }
      });
      return result;
    },
    calcSerialAndNumber() {
      if (!this.number && !this.series) return;
      let hser = parseInt(this.series, 10).toString(16);
      if (!this.series) {
        hser = "00";
      }
      let hnum = parseInt(this.number, 10).toString(16);
      if (hnum.length < 4) {
        hnum = "0000" + hnum;
        hnum = hnum.substr(hnum.length - 4, 4);
      }
      if (hser.length < 2) {
        hser = "00" + hser;
        hser = hser.substr(hnum.length - 2, 2);
      }
      let hex = hser + hnum;
      this.hexString(hex);
      this.hex_code = hex.toUpperCase();
      this.full_number = BigInt("0x" + hex).toString(10);
    },
    calcFullNumber() {
      if (!this.full_number) return;

      if (this.cardtype != "card") return this.full_number;

      this.number = "";
      this.series = "";
      this.full_number = this.full_number.replace(/[^\d]/g, "");
      let hex = BigInt(this.full_number, 10).toString(16);
      hex = hex.padStart(16 - hex.length, "0");
      this.hex_code = hex.toUpperCase();

      let hnum = hex.substr(hex.length - 4, 4);

      this.number = "0";
      if (hnum != "") {
        this.number = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);

      if (this.name == "") this.name = hex;

      this.hexString(hser + hnum);

      this.series = "0";
      if (hser != "") {
        this.series = parseInt(hser, 16).toString();
      }
    },
    calcHex() {
      if (!this.hex_code) return;
      this.number = "";
      this.series = "";
      let hx = this.hex_code;
      this.full_number = BigInt("0x" + hx).toString(10);

      let hex = parseInt(this.full_number, 10).toString(16);
      this.hexString(hex);
      let hnum = hex.substr(hex.length - 4, 4);
      this.number = "0";
      if (hnum != "") {
        this.number = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);
      this.series = "0";
      if (hser != "") {
        this.series = parseInt(hser, 16).toString();
      }
    },
    calcHexBolid() {
      if (!this.hex_code_bolid) return;
      this.number = "";
      this.series = "";
      this.hex_code = "";
      let hx = this.hex_code_bolid.substr(2, 12);

      this.full_number = BigInt("0x" + hx).toString(10);

      let hex = BigInt(this.full_number).toString(16);
      this.hex_code = hex.toUpperCase();

      let hnum = hex.substr(hex.length - 4, 4);
      this.number = "0";
      if (hnum != "") {
        this.number = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);
      this.series = "0";
      if (hser != "") {
        this.series = parseInt(hser, 16).toString();
      }
    },
    hexString(wiegand_hex) {
      let hh = wiegand_hex.padStart(12, "0");
      let b = [];
      let j = 0;
      hh = hh + "01";
      for (var i = 6; i >= 0; i--) {
        b[j] = parseInt(hh.substr(i * 2, 2), 16);
        j++;
      }
      hh = crc8(b).toString(16).padStart(2, "0") + hh;
      this.hex_code_bolid = hh.toUpperCase();
    },

    setHexCode(hexCode) {
      this.hex_code = hexCode;
      this.calcHex();
    },

    getDevices() {
      this.axios("devices").then((response) => {
        const devices = this.getDevicesWithCardReader(response.data.data);
        this.devices = devices;
        this.selectedDevice = devices[0].id;
      });
    },

    getDevicesWithCardReader(devices) {
      return devices.filter((device) => {
        const data = JSON.parse(device.data);

        if (data.card_reader) {
          return true;
        }

        return false;
      });
    },

    getItemText(item) {
      let text = item.ip_address;

      if (item.name) {
        text += ` (${item.name})`;
      }

      return text;
    },

    inputHex(hex) {
      let newHex = hex.toUpperCase();
      newHex = newHex
        .split("")
        .map((char) => {
          switch (char) {
            case "Ф":
              return "A";
            case "И":
              return "B";
            case "С":
              return "C";
            case "В":
              return "D";
            case "У":
              return "E";
            case "А":
              return "F";
            default:
              return char;
          }
        })
        .join("");

      this.hex_code = newHex;

      this.calcHex();
    },
  },
  created() {
    this.getDevices();

    const card = this.value || {};
    const isCardEmpty =
      card &&
      Object.keys(card).length === 0 &&
      Object.getPrototypeOf(card) === Object.prototype;

    if (!isCardEmpty) {
      this.id = card.id || 0;
      this.name = card.name || "";
      this.cardtype = card.type_card || "card";
      this.series = "";
      this.number = "";
      this.full_number = card.number || "";
      this.expire_date = card.expire_date;
      this.active = card.active || true;
      if (this.cardtype == "card") {
        this.calcFullNumber();
      }
    }

    this.$root.$on("cardreader", (item) => {
      if (
        this.cardtype === "card" &&
        this.isCardReaderMode &&
        this.selectedDevice === item.id
      ) {
        this.setHexCode(item.cardNum);
        this.isCardReaderMode = false;
        this.selectedDevice = null;
      }
    });
  },

  destroyed() {
    this.$root.$off("cardreader");
  },
};
</script>
<style scoped></style>
