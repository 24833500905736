<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row
      v-if="
        $auth.user().type_user == 'admin' ||
        $auth.user().roles.includes('requestsemployee') ||
        isReadonly ||
        isExistUser
      "
    >
      <v-col>
        <v-radio-group
          v-model="stateUserType"
          row
          hide-details
          :disabled="isReadonly || isExistUser"
          @change="changeForm(false)"
        >
          <v-radio :label="$ml.get('requests.guest')" value="guest" />
          <v-radio :label="$ml.get('requests.employee')" value="employee" />
        </v-radio-group>
      </v-col>

      <v-spacer />

      <v-col cols="auto">
        <v-checkbox
          v-model="isOnlyByQR"
          :label="$ml.get('requests.onlyByQR')"
          :disabled="isReadonly || isExistUser"
          hide-details
          @change="changeForm"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isMulti" cols="12" md="6">
        <FullName
          :surname="surname"
          :name="firstname"
          :patronymic="patronymic"
          :disabled="isReadonly || isExistUser"
          @change="onChangeName"
        />
      </v-col>
      <v-col cols="12" :md="isMulti ? 12 : 6">
        <v-text-field
          v-model="stateDescription"
          :label="$ml.get('requests.description')"
          outlined
          persistent-hint
          :hint="descriptionHint"
          :disabled="isReadonly"
          @input="changeForm(false)"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isMulti">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="stateEmail"
          ref="email"
          type="email"
          :label="emailLabel"
          :rules="emailRules"
          :disabled="isReadonly"
          outlined
          :loading="isCheckEmailPending"
          @input="inputEmail"
        />
      </v-col>
      <v-col cols="12" md="6">
        <vue-tel-input-vuetify
          v-model="statePhone"
          ref="phone"
          outlined
          :label="phoneLabel"
          :rules="phoneRules"
          :disabled="isReadonly"
          :loading="isCheckPhonePending"
          :preferredCountries="[
            'RU',
            'BY',
            'KZ',
            'MD',
            'AM',
            'AZ',
            'GE',
            'KG',
            'TJ',
            'TM',
            'UZ',
          ]"
          placeholder=""
          @input="inputPhone"
          @focus="onFocusPhone"
          @blur="onBlurPhone"
        />
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col cols="12" md="6">
        <treeselect
          v-model="stateDepartment"
          :multiple="false"
          :options="departments"
          :placeholder="`${$ml.get('user.departments')} *`"
          :searchable="true"
          :show-count="true"
          :disabled="isReadonly || (isExistUser && !!stateDepartment)"
          @input="onChangeDepartments"
        />
        <div
          v-if="!validDepartments"
          class="v-messages theme--light error--text pl-3 pr-3 mt-2 mb-2"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ $ml.get("message.NameIsRequired") }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <treeselect
          v-model="stateSubdivisions"
          :multiple="true"
          :options="companies"
          :placeholder="`${$ml.get('requests.subdivisions')} *`"
          :searchable="true"
          :show-count="true"
          :disabled="isReadonly || (isExistUser && !!stateSubdivisions.length)"
          flat
          @input="onChangeSubdivisions"
        >
          <label
            slot="option-label"
            slot-scope="{
              node,
              shouldShowCount,
              count,
              labelClassName,
              countClassName,
            }"
            :class="labelClassName"
          >
            <v-row no-gutters align="center">
              <v-col>
                {{ node.label }}
                <span v-if="shouldShowCount" :class="countClassName">
                  ({{ count }})
                </span>
              </v-col>
              <v-col cols="auto">
                <v-tooltip v-if="node.raw.is_guest" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="mr-2" small
                      >mdi-account-clock</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("subdiv.guest") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </label>
        </treeselect>
        <div
          v-if="!validSubdivisions"
          class="v-messages theme--light error--text pl-3 pr-3 mt-2 mb-2"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ $ml.get("message.NameIsRequired") }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-show="stateUserType === 'guest'" cols="12" md="6">
        <v-radio-group
          v-model="isOnepass"
          row
          class="mt-0"
          :disabled="isReadonly || isExistUser"
          @change="changeForm"
        >
          <v-radio :label="$ml.get('requests.onepass')" :value="true" />
          <v-radio :label="$ml.get('requests.multiPass')" :value="false" />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="stateUserType === 'employee' && !isExistUser"
        cols="12"
        md="6"
      >
        <CardsAccess
          v-if="!isMulti"
          v-model="stateCards"
          :isAddQR="false"
          :disabled="isReadonly"
          @input="changeForm"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="isRegByQR"
              :label="$ml.get('requests.byLink')"
              class="p-0 m-0"
              :disabled="isReadonly"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="isRegByKiosk"
              :label="$ml.get('requests.byKiosk')"
              class="p-0 m-0"
              :disabled="isReadonly"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="stateUserType === 'guest' && !isReadonly && !isExistUser">
      <v-col cols="12">
        <h6 v-show="!isCustomDue">
          {{ $ml.get("requests.on24Hours") }}
          <v-btn x-small @click="isCustomDue = true">{{
            $ml.get("requests.setCustomDue")
          }}</v-btn>
        </h6>
        <v-btn
          v-show="isCustomDue"
          color="error"
          x-small
          @click="isCustomDue = false"
          >{{ $ml.get("requests.unsetCustomDue") }}</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-show="stateUserType === 'guest' && (isCustomDue || isReadonly)">
      <v-col v-if="stateDueDateFrom" cols="12" md="6">
        <v-row>
          <v-col cols="12" class="pb-0 pt-0">
            {{ $ml.get("requests.dueFrom") }}
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="dueDateFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFrom"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :placeholder="$ml.get('requests.date')"
                  outlined
                  :disabled="isReadonly"
                />
              </template>
              <v-date-picker
                v-model="stateDueDateFrom"
                :min="dueFromMin"
                :first-day-of-week="firstDayOfWeek"
                @input="onChangeDueDateFrom"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dueDateFromMenu = false">
                  {{ $ml.get("button.close") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="time"
              v-model="stateDueTimeFrom"
              :disabled="!stateDueDateFrom || isReadonly"
              :placeholder="$ml.get('requests.time')"
              outlined
              @click:minute="stateDueTimeFrom"
              @change="changeForm"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="stateDueDateTo" cols="12" md="6">
        <v-row>
          <v-col cols="12" class="pb-0 pt-0">
            {{ $ml.get("requests.dueTo") }}
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="dueDateToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateTo"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :placeholder="$ml.get('requests.date')"
                  outlined
                  :disabled="isReadonly"
                  @change="changeForm"
                />
              </template>
              <v-date-picker
                v-model="stateDueDateTo"
                :min="dueToMin"
                :first-day-of-week="firstDayOfWeek"
                @input="onChangeDueDateTo"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dueDateToMenu = false">
                  {{ $ml.get("button.close") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="time"
              v-model="stateDueTimeTo"
              :disabled="!stateDueDateTo || isReadonly"
              :placeholder="$ml.get('requests.time')"
              outlined
              @click:minute="onChangeDueTimeTo"
              @change="changeForm"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-show="stateUserType === 'guest'">
      <v-col cols="12">
        <v-textarea
          v-model="statePurpose"
          rows="1"
          auto-grow
          :label="$ml.get('requests.purpose')"
          outlined
          persistent-hint
          :hint="$ml.get('requests.purposeHint')"
          :disabled="isReadonly"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import debounce from "lodash.debounce";
import calendar from "@/mixins/calendar";
import CardsAccess from "@/components/CardsAccess";
import FullName from "@/components/fullname";

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

export default {
  mixins: [calendar],

  components: { Treeselect, FullName, CardsAccess },

  props: {
    isMulti: {
      type: Boolean,
      default: false,
    },

    id: {
      type: Number,
      default: 0,
    },
    user_id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    dueFrom: {
      type: String,
      default: "",
    },
    dueTo: {
      type: String,
      default: "",
    },
    subdivisions: {
      type: Array,
      default: () => [],
    },
    department: {
      type: Number,
      default: null,
    },
    status: {
      type: Number,
      default: 0,
    },
    requestType: {
      type: Number,
      default: 1,
    },
    onepass: {
      type: Boolean,
      default: true,
    },
    onlyByQR: {
      type: Boolean,
      default: false,
    },
    purpose: {
      type: String,
      default: "",
    },
    userType: {
      type: String,
      default: "guest",
    },
    cards: {
      type: Array,
      default: () => [],
    },

    local: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    const stateName = this.name;
    const stateDescription = this.description;
    const stateEmail = this.email;
    const statePhone = this.phone;

    let stateDueDateFrom = null;
    let stateDueTimeFrom = "";
    let stateDueDateTo = null;
    let stateDueTimeTo = "";

    if (
      (this.status === 0 &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("requests"))) ||
      this.dueFrom
    ) {
      stateDueDateFrom = this.dueFrom
        ? this.$moment(this.dueFrom).format(dateFormat)
        : this.$moment().format(dateFormat);
      stateDueTimeFrom = this.dueFrom
        ? this.$moment(this.dueFrom).format(timeFormat)
        : this.$moment().format(timeFormat);
    }

    if (
      (this.status === 0 &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("requests"))) ||
      this.dueTo
    ) {
      stateDueDateTo = this.dueTo
        ? this.$moment(this.dueTo).format(dateFormat)
        : this.$moment().add(24, "hour").format(dateFormat);
      stateDueTimeTo = this.dueTo
        ? this.$moment(this.dueTo).format(timeFormat)
        : this.$moment().add(24, "hour").format(timeFormat);
    }

    const isOnepass = this.onepass;
    const isOnlyByQR = this.onlyByQR;
    let isRegByQR = true;
    let isRegByKiosk = false;

    const requestTypeSplit = String(this.requestType)
      .split("")
      .map((num) => {
        return Number(num);
      });

    if (requestTypeSplit.includes(1)) {
      isRegByQR = true;
    } else {
      isRegByQR = false;
    }

    if (requestTypeSplit.includes(0)) {
      isRegByKiosk = true;
    } else {
      isRegByKiosk = false;
    }

    const stateDepartment = this.department || null;

    const stateSubdivisions = this.subdivisions;

    const statePurpose = this.purpose;
    const stateUserType = this.userType;

    return {
      isValidPhone: true,
      isLockSetLocal: false,

      stateName,
      stateDescription,
      stateEmail,
      statePhone,
      isOnepass,
      isOnlyByQR,
      stateCards: this.cards,

      dueDateFromMenu: false,
      dueTimeFromMenu: false,
      dueDateToMenu: false,
      dueTimeToMenu: false,
      stateDueDateFrom,
      stateDueTimeFrom,
      stateDueDateTo,
      stateDueTimeTo,
      statePurpose,
      stateUserType,
      isCustomDue: false,

      stateSubdivisions,
      companies: [],

      stateDepartment,
      departments: [],

      isRegByQR,
      isRegByKiosk,

      valid: true,
      validSubdivisions: true,
      validDepartments: true,

      isCheckPhonePending: false,
      isPhoneExist: false,

      isCheckEmailPending: false,
      isEmailExist: false,

      emailRules: [
        () => {
          if (!this.stateEmail && !this.statePhone) {
            return this.$ml.get("requests.oneOfContactIsRequired");
          }

          return true;
        },
        (v) => {
          if (this.stateEmail) {
            return (
              this.validateEmail(v) || this.$ml.get("user.email_char_valid")
            );
          }

          return true;
        },
        () => !this.isEmailExist || this.$ml.get("requests.emailExist"),
      ],
      phoneRules: [
        () => {
          if (!this.stateEmail && !this.statePhone) {
            return this.$ml.get("requests.oneOfContactIsRequired");
          }

          return true;
        },
        (v) => {
          if (!v.length) {
            return true;
          }
          return (
            (!/.?[^ 0-9()\-+]/.test(v) && this.isValidPhone) ||
            this.$ml.get("user.notPhone")
          );
        },
        () => !this.isPhoneExist || this.$ml.get("requests.phoneExist"),
      ],
    };
  },

  watch: {
    isRegByQR(isRegByQR) {
      if (!isRegByQR) {
        this.isRegByKiosk = true;
      }
      this.changeForm();
    },

    isRegByKiosk(isRegByKiosk) {
      if (!isRegByKiosk) {
        this.isRegByQR = true;
      }
      this.changeForm();
    },

    async stateEmail() {
      await this.$nextTick();
      this.$refs.phone.$refs.input.validate();
    },

    async statePhone() {
      await this.$nextTick();
      this.$refs.email.validate();
    },

    stateUserType() {
      this.setLocalValues();
    },
  },

  methods: {
    changeForm(lockSetLocalTrigger = true) {
      if (lockSetLocalTrigger) {
        this.isLockSetLocal = true;
      }

      const {
        stateName: name,
        stateDescription: description,
        stateEmail: email,
        statePhone: phone,
        isOnepass: onepass,
        isOnlyByQR: only_by_qr,
        isCustomDue,
        stateDueDateFrom,
        stateDueTimeFrom,
        stateDueDateTo,
        stateDueTimeTo,
        stateSubdivisions: subdivisions,
        stateDepartment: department,
        requestTypeInt: request_type,
        statePurpose: purpose,
        status,
        stateUserType: user_type,
        stateCards: cards,
      } = this;

      const contacts = {
        email,
        phone,
        onepass,
        only_by_qr,
        purpose,
        department,
        user_type,
        cards,
      };

      let due_from = null;
      if (isCustomDue) {
        due_from = this.$moment(
          `${stateDueDateFrom} ${stateDueTimeFrom}`
        ).toISOString();
      } else {
        due_from = this.$moment().toISOString();
      }

      let due_to = null;
      if (isCustomDue) {
        due_to = this.$moment(
          `${stateDueDateTo} ${stateDueTimeTo}`
        ).toISOString();
      } else {
        due_to = this.$moment().add(24, "hour").toISOString();
      }

      this.$emit("change", {
        name,
        description,
        contacts,
        due_from,
        due_to,
        subdivisions,
        request_type,
        status,
      });
    },

    onChangeSubdivisions(e) {
      this.validateSubdivisions();
      this.changeForm(false);
    },

    onChangeDepartments() {
      this.validateDepartments();

      if (this.statePhone) {
        this.checkPhone(this.statePhone);
      }

      if (this.stateEmail) {
        this.checkEmail(this.stateEmail);
      }

      this.changeForm(false);
    },

    getCompanies() {
      this.axios.get("subdivisions/tree").then((response) => {
        this.companies = response.data.data ? response.data.data : [];
      });
    },

    onChangeDueDateFrom(from) {
      const to = this.stateDueDateTo;
      const isFromAfterTo = this.$moment(from).isAfter(to);

      if (isFromAfterTo) {
        this.stateDueDateTo = this.$moment(from)
          .add(1, "day")
          .format(dateFormat);
      }

      this.dueDateFromMenu = false;

      this.changeForm();
    },

    onChangeDueTimeFrom() {
      this.$refs.dueTimeFromMenu.save(this.stateDueTimeFrom);

      this.changeForm();
    },

    onChangeDueDateTo() {
      this.dueDateToMenu = false;

      this.changeForm();
    },

    onChangeDueTimeTo() {
      this.$refs.dueTimeToMenu.save(this.stateDueTimeTo);

      this.changeForm();
    },

    getDepartments() {
      this.axios.get("departments/tree").then((response) => {
        this.departments = response.data.data ? response.data.data : [];
        if (!this.stateDepartment && this.departments.length) {
          this.stateDepartment = this.departments[0].id;
        }
      });
    },

    validateSubdivisions() {
      this.validSubdivisions = true;

      if (!this.stateSubdivisions.length) {
        this.validSubdivisions = false;
      }

      return this.validSubdivisions;
    },

    validateDepartments() {
      this.validDepartments = true;

      if (!this.stateDepartment) {
        this.validDepartments = false;
      }

      return this.validDepartments;
    },

    validateEmail(v) {
      var re =
        /^(([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(v);
    },

    onFocusPhone() {
      this.isShowPhonePrefix = true;
    },

    onBlurPhone() {
      if (this.statePhone) {
        this.isShowPhonePrefix = true;
      } else {
        this.isShowPhonePrefix = false;
      }
    },

    inputPhone(phone, { valid }) {
      this.isValidPhone = valid;

      if (this.isValidPhone) {
        this.changePhone(phone);
      }
    },

    changePhone: debounce(async function (phone) {
      await this.checkPhone(phone);
      this.changeForm();
    }, 300),

    checkPhone(phone) {
      this.isCheckPhonePending = true;
      this.isPhoneExist = false;

      const params = {
        phone,
        department: this.stateDepartment,
      };

      if (this.user_id) {
        params.user_id = this.user_id;
      }

      if (this.id) {
        params.id = this.id;
      }

      return this.axios("request/check", { params })
        .then((response) => {
          this.isPhoneExist = response.data.data.result_phone;
          this.isCheckPhonePending = false;
        })
        .catch(() => {
          this.isCheckPhonePending = false;
          this.isPhoneExist = false;
        })
        .finally(() => {
          this.$refs.phone.$refs.input.validate();
        });
    },

    inputEmail: debounce(async function (email) {
      this.checkEmail(email);
      this.changeForm();
    }, 300),

    checkEmail(email) {
      this.isCheckEmailPending = true;
      this.isEmailExist = false;

      const params = {
        email,
        department: this.stateDepartment,
      };

      if (this.id) {
        params.id = this.id;
      }

      return this.axios("request/check", { params })
        .then((response) => {
          this.isEmailExist = response.data.data.result_email;
          this.isCheckEmailPending = false;
        })
        .catch(() => {
          this.isCheckEmailPending = false;
          this.isEmailExist = false;
        })
        .finally(() => {
          this.$refs.email.validate();
        });
    },

    setLocalValues() {
      if (!this.isLockSetLocal && this.local) {
        this.stateDescription = this.local.description[this.stateUserType];

        const existDepartment = this.departments.filter(
          (department) =>
            department.id === this.local.department[this.stateUserType]
        );

        if (existDepartment.length) {
          this.stateDepartment = this.local.department[this.stateUserType];
        }

        const existSubdivision = this.companies.filter(
          (subdivision) =>
            subdivision.id === this.local.subdivisions[this.stateUserType]
        );

        if (existSubdivision.length) {
          this.stateSubdivisions = this.local.subdivisions[this.stateUserType];
        }
      }
    },

    onChangeName({ surname, name, patronymic }) {
      this.stateName = `${surname} ${name}`;

      if (patronymic) {
        this.stateName += ` ${patronymic}`;
      }

      this.changeForm();
    },
  },

  computed: {
    computedDateFrom() {
      return this.formatLocateDate(this.stateDueDateFrom);
    },

    computedDateTo() {
      return this.formatLocateDate(this.stateDueDateTo);
    },

    dueFromMin() {
      const date = this.$moment().format(dateFormat);

      return date;
    },

    dueToMin() {
      const date = this.stateDueDateFrom || this.dueFromMin;

      return date;
    },

    emailLabel() {
      let label = this.$ml.get("requests.email");

      if (!this.statePhone) {
        label += " *";
      }

      return label;
    },

    phoneLabel() {
      let label = this.$ml.get("requests.phone");

      if (!this.stateEmail) {
        label += " *";
      }

      return label;
    },

    isReadonly() {
      if (
        this.status === 0 &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("requests"))
      ) {
        return false;
      }

      return true;
    },

    isExistUser() {
      if (this.user_id) {
        return true;
      }

      return false;
    },

    requestTypeInt() {
      const { isRegByQR, isRegByKiosk } = this;

      let requestType = "";

      if (isRegByQR) {
        requestType += "1";
      }

      if (isRegByKiosk) {
        requestType += "0";
      }

      requestType = parseInt(requestType);

      return requestType;
    },

    descriptionHint() {
      switch (this.stateUserType) {
        case "guest":
          return this.$ml.get("requests.descriptionGuestHint");
        case "employee":
          return this.$ml.get("requests.descriptionEmployeeHint");
        default:
          return this.$ml.get("requests.descriptionHint");
      }
    },

    surname() {
      let result = "";

      const splitName = this.name.trim().split(" ");

      if (splitName[0]) {
        result = splitName[0];
      }

      return result;
    },

    firstname() {
      let result = "";

      const splitName = this.name.trim().split(" ");

      if (splitName[1]) {
        result = splitName[1];
      }

      return result;
    },

    patronymic() {
      let result = "";

      const splitName = this.name.trim().split(" ");

      if (splitName[2]) {
        result = splitName[2];
      }

      return result;
    },
  },

  created() {
    this.getCompanies();
    this.getDepartments();
    this.setLocalValues();
  },
};
</script>
