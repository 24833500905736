<template>
  <v-app>
    <v-skeleton-loader v-if="isPending" type="card-avatar" />

    <div v-if="isSuccess" class="d-flex add-new-visitor-form-container">
      <div class="user-card mb-3">
        <div class="column image-column col-sm-4">
          <img :src="user.avatar" width="100%" alt="photo" id="avatar" />
        </div>
        <div class="column form-column col-sm-8">
          <v-row v-if="!user.deleted" class="mb-2">
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                v-if="
                  user.request_id &&
                  ($auth.user().type_user == 'admin' ||
                    $auth.user().roles.includes('reqview'))
                "
                color="primary"
                depressed
                link
                :to="{ name: 'editRequest', params: { id: user.request_id } }"
              >
                {{ $ml.get("user.toRequestPage") }}
              </v-btn>
              <v-btn
                v-if="
                  !user.request_id &&
                  ($auth.user().type_user == 'admin' ||
                    $auth.user().roles.includes('requests'))
                "
                color="primary"
                depressed
                link
                :to="{ name: 'addRequest', params: { user: user } }"
              >
                {{ $ml.get("user.createRequest") }}
              </v-btn>
            </v-col>
          </v-row>

          <table style="table-layout: fixed">
            <tbody>
              <tr>
                <td colspan="2">
                  <div v-if="user.error" class="alert alert-danger">
                    <div class="container p-0">
                      <div class="row">
                        <div class="col-9 d-flex align-items-center">
                          {{ $ml.get("message." + user.error) }}
                        </div>
                        <div class="col-3 d-flex justify-content-end">
                          <button
                            v-if="user.error == 'NoBiometricData'"
                            type="button"
                            class="btn btn-primary"
                            @click="refeature"
                          >
                            {{ $ml.get("button.refeature") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="user.deleted" class="alert alert-danger">
                    {{ $ml.get("user.deleted") }}
                  </div>
                  <template v-else>
                    <div
                      v-if="user.on_object"
                      class="alert alert-success"
                      role="alert"
                    >
                      <strong>{{ $ml.get("statistic.advanced") }}</strong>
                    </div>
                    <div v-else class="alert alert-warning" role="alert">
                      <strong>{{ $ml.get("statistic.notAdvanced") }}</strong>
                    </div>
                  </template>

                  <div
                    v-if="user.is_twin"
                    class="alert alert-info"
                    role="alert"
                  >
                    <strong>{{ $ml.get("user.isTwin") }}</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="grey--text" width="240">
                  {{ $ml.get("users.fullname") }}:
                </td>
                <td>{{ user.fullName }}</td>
              </tr>
              <tr v-if="user.type_user">
                <td class="grey--text">{{ $ml.get("users.userType") }}:</td>
                <td>{{ $ml.get("users." + user["type_user"]) }}</td>
              </tr>
              <tr v-if="user.department">
                <td class="grey--text">{{ $ml.get("users.department") }}:</td>
                <td>{{ user.department.name }}</td>
              </tr>
              <template v-if="user['type_user'] == 'guest'">
                <tr>
                  <td class="grey--text">
                    {{ $ml.get("user.visit_purpose") }}:
                  </td>
                  <td>{{ visit_purpose }}</td>
                </tr>
                <tr v-if="onepass">
                  <td class="grey--text">
                    {{ $ml.get("user.type_of_pass") }}:
                  </td>
                  <td v-if="onepass">{{ $ml.get("user.pass_one") }}</td>
                  <td v-if="!onepass">{{ $ml.get("user.pass_all") }}</td>
                </tr>
                <tr v-if="expireDate">
                  <td class="grey--text">{{ $ml.get("user.expireDate") }}:</td>
                  <td>{{ expireDate }}</td>
                </tr>
              </template>
              <tr>
                <td class="grey--text">{{ $ml.get("users.subdivision") }}:</td>
                <td>
                  <div
                    v-for="sdv in user.access_groups"
                    :key="sdv.id"
                    class="companies-item"
                  >
                    {{ sdv.name }}
                  </div>
                </td>
              </tr>
              <tr v-if="user.type_user == 'employee'">
                <td class="grey--text">{{ $ml.get("user.post") }}:</td>
                <td>{{ post }}</td>
              </tr>
              <tr v-if="user.accessLevel">
                <td class="grey--text">{{ $ml.get("user.permission") }}:</td>
                <td>{{ $ml.get("access." + user.accessLevel) }}</td>
                v-if=""
              </tr>
              <tr v-if="user.phone">
                <td class="grey--text">{{ $ml.get("user.phone") }}:</td>
                <td>{{ user.phone }}</td>
              </tr>
              <tr
                v-if="
                  tabNum &&
                  (user.type_user == 'employee' || user.type_user == 'manager')
                "
              >
                <td class="grey--text">{{ $ml.get("user.tabNum") }}:</td>
                <td style="overflow: hidden; word-break: break-word">
                  {{ tabNum }}
                </td>
              </tr>
              <tr v-if="user.documentNumber">
                <td class="grey--text">
                  {{ $ml.get("documents." + user.documentType) }}:
                </td>
                <td>{{ user.documentNumber }}</td>
              </tr>
              <tr>
                <td class="grey--text">ID:</td>
                <td>{{ user.id }}</td>
              </tr>
              <tr v-if="user.externalId">
                <td class="grey--text">External ID:</td>
                <td>{{ user.externalId }}</td>
              </tr>
              <tr v-if="user.created_at">
                <td class="grey--text">{{ $ml.get("user.createdAt") }}:</td>
                <td>{{ user.created_at | moment("DD.MM.YYYY HH:mm") }}</td>
              </tr>
              <tr v-if="user.deleted && user.deleted_at">
                <td class="grey--text">{{ $ml.get("user.deletedAt") }}:</td>
                <td>{{ user.deleted_at | moment("DD.MM.YYYY HH:mm") }}</td>
              </tr>
            </tbody>
          </table>
          <v-row
            v-if="
              $auth.user().type_user == 'admin' ||
              $auth.user().type_user == 'guard' ||
              $auth.user().type_user == 'manager'
            "
          >
            <v-col cols="auto">
              <button
                v-if="isEditUser() && $auth.user().id != id"
                class="btn btn-success mr-1"
                @click="goEdit"
              >
                {{ $ml.get("user.edit") }}
              </button>
            </v-col>
            <template
              v-if="
                user.type_user != 'admin' &&
                isEditUser() &&
                !user.deleted &&
                $auth.user().id != id
              "
            >
              <v-col v-if="!user.blocked" cols="auto">
                <button
                  class="btn btn-light mr-1"
                  type="button"
                  @click="blocked(1)"
                  id="user-blocked"
                >
                  {{ $ml.get("button.block") }}
                </button>
              </v-col>
              <v-col v-else cols="auto">
                <button
                  class="btn btn-danger mr-1"
                  type="button"
                  @click="blocked(0)"
                  id="user-unblocked"
                >
                  {{ $ml.get("button.unblock") }}
                </button>
              </v-col>
              <v-col v-if="!user.blacklisted" cols="auto">
                <button
                  class="btn btn-light mr-1"
                  type="button"
                  @click="blacklisted(1)"
                  id="user-blocked"
                >
                  {{ $ml.get("button.add_blacklist") }}
                </button>
              </v-col>
              <v-col v-else cols="auto">
                <button
                  class="btn btn-black mr-1"
                  type="button"
                  @click="blacklisted(0)"
                  id="user-unblocked"
                >
                  {{ $ml.get("button.remove_blacklist") }}
                </button>
              </v-col>
            </template>
          </v-row>
        </div>
      </div>

      <div class="user-card user-card-statistic p-3">
        <h3>{{ $ml.get("user.visitStatistics") }}</h3>
        <div class="row">
          <div class="col-sm-2">
            <select
              class="custom-select"
              v-model="thismonth"
              @change="getVisits"
            >
              <option
                v-for="month in months"
                :key="month.name"
                :value="month.name"
                :selected="month.name == thismonth"
              >
                {{ month.title }}
              </option>
            </select>
          </div>
          <div
            v-if="visiteStatistic.userentrance"
            class="col-sm-10"
            style="font-weight: bold; color: green"
          >
            {{ visiteStatistic.userentrance }}
          </div>
        </div>
        <div style="display: flex; flex-direction: column; margin-top: 10px">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#short" data-toggle="tab">{{
                $ml.get("user.abbreviated")
              }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#allvisits" data-toggle="tab">{{
                $ml.get("user.allPassages")
              }}</a>
            </li>
          </ul>
        </div>
        <div
          class="tab-content"
          style="padding: 5px; border-top: 1px solid #dee2e6; margin-top: -1px"
        >
          <div class="row tab-pane active" id="short">
            <div class="col-sm-12">
              <table class="last-visitors-statistic">
                <thead>
                  <tr>
                    <th>{{ $ml.get("user.statDate") }}</th>
                    <th width="20%">
                      {{ $ml.get("device.purpose_entrance") }}
                    </th>
                    <th width="20%">{{ $ml.get("device.purpose_exit") }}</th>
                    <th width="20%" style="text-align: left">
                      {{ $ml.get("statistic.onObject") }}
                    </th>
                    <!-- <th width="20%" style="text-align: left;">Вне</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!visiteStatistic.days">
                    <td class="no-results" colspan="6">
                      {{ $ml.get("statistic.noVisit") }}
                    </td>
                  </tr>
                  <template v-else>
                    <tr v-for="visit in visiteStatistic.days" :key="visit.day">
                      <td>{{ visit.day | moment("DD.MM.YYYY") }}</td>
                      <td>{{ visit.firstEntering }}</td>
                      <td>{{ visit.lastExit }}</td>
                      <td>{{ visit.workTime }}</td>
                      <!-- <td>{{ visit.outWorkTime }}</td> -->
                    </tr>
                  </template>
                  <tr>
                    <th colspan="3" style="text-align: right">
                      {{ $ml.get("statistic.totalMonth") }}:
                    </th>
                    <th style="padding: 10px">{{ visiteStatistic.total }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row tab-pane" id="allvisits">
            <div class="col-sm-12">
              <table class="last-visitors-statistic" width="100%">
                <thead>
                  <tr>
                    <th width="60px"></th>
                    <th>{{ $ml.get("user.statDate") }}</th>
                    <th>{{ $ml.get("statistic.time") }}</th>
                    <th>
                      {{ $ml.get("statistic.zone") }}
                    </th>
                    <th style="text-align: right">
                      °{{ l.temp_meas.toUpperCase() }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!visiteStatistic.visits.length">
                    <td class="no-results" colspan="6">
                      {{ $ml.get("statistic.noVisit") }}
                    </td>
                  </tr>
                  <template v-if="visiteStatistic.visits.length">
                    <tr v-for="vis in visiteStatistic.visits" :key="vis.id">
                      <td>
                        <span class="mr-1">
                          <v-tooltip
                            v-if="
                              vis.purpose === 'entrance' ||
                              vis.purpose === 'duplex'
                            "
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="success"
                                >mdi-location-enter</v-icon
                              >
                            </template>
                            <span>{{
                              $ml.get("device.purpose_entrance")
                            }}</span>
                          </v-tooltip>
                          <v-tooltip v-if="vis.purpose === 'exit'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="error"
                                class="rotate-180"
                                >mdi-location-exit</v-icon
                              >
                            </template>
                            <span>{{ $ml.get("device.purpose_exit") }}</span>
                          </v-tooltip>
                        </span>

                        <span v-for="type in vis.identity_types" :key="type">
                          <v-tooltip v-if="type === 'face'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-emoticon-happy-outline
                              </v-icon>
                            </template>
                            <span>{{ $ml.get("users.face") }}</span>
                          </v-tooltip>

                          <v-tooltip v-if="type === 'qr'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-qrcode-scan
                              </v-icon>
                            </template>
                            <span>{{ $ml.get("users.qr") }}</span>
                          </v-tooltip>

                          <v-tooltip v-if="type === 'card'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-card-bulleted-outline
                              </v-icon>
                            </template>
                            <span>{{ $ml.get("users.card") }}</span>
                          </v-tooltip>
                        </span>
                      </td>

                      <td>{{ vis.day | moment("DD.MM.YYYY") }}</td>
                      <td>{{ vis.time }}</td>
                      <td>{{ vis.zone }}</td>
                      <td style="text-align: right">
                        <span v-if="parseFloat(vis.temperature)">{{
                          calculateCalcFeel(parseFloat(vis.temperature))
                        }}</span>
                        <span v-if="!parseFloat(vis.temperature)">-</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";

export default {
  data() {
    return {
      thismonth: "",
      isPending: false,
      isSuccess: false,
      isFailure: false,
      user: {
        id: 0,
        avatar: "/avatar/profile/avatar.png",
        fullName: "",
      },
      months: [],
      visiteStatistic: {
        days: [],
        visits: [],
        total: "",
      },
      expireDate: "",
      onepass: false,
      id: this.$route.params["id"],
      visit_purpose: "",
      post: "",
      tabNum: "",
    };
  },

  async created() {
    await this.getUser();
    this.setMonths();
  },

  methods: {
    isEditUser() {
      return (
        !this.l.cloud_connect &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("useredit") ||
          this.$auth.user().roles.includes("userdel"))
      );
    },
    goEdit() {
      router.push({ path: `/user/${this.id}/edit` });
    },
    formatdate(date) {
      let d = date.split("/");
      d[2] = d[2] - 2000;
      return d.join(".");
    },
    blocked(stat) {
      let com = stat == 1 ? "blocked" : "unblocked";
      this.axios
        .put("/user/" + com, { ids: [parseInt(this.id)] })
        .then((response) => {
          if (response.status == 200 && response.data.status.code == 0) {
            this.user.blocked = stat;

            if (com === "blocked") {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userBlockSuccess"),
              });
            } else {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userUnblockSuccess"),
              });
            }
          }
        });
    },
    blacklisted(stat) {
      let com = stat == 1 ? "blacklisted" : "unblacklisted";
      this.axios
        .put("/user/" + com, { ids: [parseInt(this.id)] })
        .then((response) => {
          if (response.status == 200 && response.data.status.code == 0) {
            this.user.blacklisted = stat;

            if (com === "blacklisted") {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userBlacklistSuccess"),
              });
            } else {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userUnblacklistSuccess"),
              });
            }
          }
        });
    },
    getUser() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      return this.axios
        .get("/user/" + this.id)
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.getVisits();
          this.user = response.data.data ? response.data.data : {};
          this.user.fullName = (
            this.user.last_name +
            " " +
            this.user.first_name +
            " " +
            this.user.middle_name
          ).trim();
          this.user.avatar = this.user.photo
            ? "/avatar/profile/" + this.user.photo
            : "/avatar/profile/" + "avatar.png";
          if (this.user.data) {
            let data = JSON.parse(this.user.data);
            this.visit_purpose = data.visit_purpose || "";
            this.post = data.post || "";
            this.onepass = data.onepass || false;
            this.expireDate = data.expire_date
              ? this.$moment(data.expire_date).format("DD.MM.YYYY HH:mm")
              : "";
            this.tabNum = data.tab_num;
          }
        })
        .catch((error) => {
          const message = error.response.data.status.message;

          switch (message) {
            case "record not found":
              this.$notify({
                group: "info",
                type: "error",
                text: this.$ml.get("message.noUser"),
              });
              break;
            default:
              this.$notify({
                group: "info",
                type: "error",
                text: message,
              });
              break;
          }

          this.$router.go(-1);

          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
        });
    },
    calculateCalcFeel(t) {
      if (this.l.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },

    setMonths() {
      let m = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
      };

      let tm = new this.$moment();
      //.format("YYYY-MM")
      for (let i = 0; i < 12; i++) {
        this.months.push({
          name: tm.format("YYYY-MM"),
          title:
            tm.format("YYYY") +
            " - " +
            this.$ml.get("dtpicker." + m[tm.format("MM")]),
        });
        tm.add(-1, "M");
      }
      if (this.months.length > 0) {
        this.thismonth = this.months[0].name;
      }
    },

    getVisits() {
      this.visiteStatistic.days = [];
      this.visiteStatistic.visits = [];
      this.axios("/user/" + this.id + "/visits?month=" + this.thismonth).then(
        (response) => {
          if (response.status == 200) {
            let data = response.data.data ? response.data.data : {};
            if (data) {
              if (data.days) {
                data.days.forEach((value) => {
                  this.visiteStatistic.days.push({
                    day: value.day,
                    firstEntering: value.first_entering,
                    lastExit: value.last_exit,
                    workTime: value.work_time,
                    outWorkTime: value.out_work_time,
                  });
                });
              }
              this.visiteStatistic.total = data.total;
              if (data.visits) {
                data.visits.forEach((value) => {
                  const data = JSON.parse(value.data);

                  this.visiteStatistic.visits.push({
                    day: value.day,
                    purpose: value.purpose,
                    time: value.time_purpose,
                    zone: data?.zone,
                    type: data?.type,
                    temperature: data?.temperature,
                    identity_types: this.getIdentityTypesArr(
                      data.identity_types
                    ),
                  });
                });
              }
            }
          }
        }
      );
    },

    getIdentityTypesArr(types) {
      let result = ["face"];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    refeature() {
      this.axios
        .put(`/user/${this.user.id}/refeature`)
        .then((response) => {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.refeature_success"),
          });
          this.user.error = "";
        })
        .catch((err) => {
          this.$notify({
            group: "info",
            type: "error",
            text: err.response.data.status.message,
          });
        });
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
    }),
  },
};
</script>

<style scoped>
.nav-pills .nav-link {
  border-radius: 0.25rem 0.25rem 0 0;
}
.btn.btn-black {
  background: #000;
  color: #fff;
}
.last-visitors-statistic td,
.last-visitors-statistic th {
  padding-left: 10px;
  padding-right: 10px;
}
@media only screen and (min-width: 1200px) {
  .form-column {
    width: calc(100% - 355px);
  }
}
.form-column > table {
  width: 100%;
}
</style>
