var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"fields":_vm.fields,"rows":_vm.data,"click":_vm.onClick,"dblclick":_vm.onDblClick,"rowstyle":_vm.rowstyle},scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"50px","height":"50px","border-radius":"50%","object-fit":"cover"},attrs:{"src":item.avatar},on:{"mouseenter":function($event){item.isHasPhoto ? _vm.onHoverImg(item.photo) : null},"mouseleave":_vm.onLeaveHoverImg}})]}},{key:"item.identity_types",fn:function(ref){
var item = ref.item;
return [_c('span',[(
          item.purposeType === 'entrance' || item.purposeType === 'duplex'
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-enter")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])]):_vm._e(),(item.purposeType === 'exit')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rotate-180",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-exit")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])]):_vm._e()],1),(item.eventType !== 'deviceAlarm')?_vm._l((item.identity_types),function(type){return _c('span',{key:type},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("users.qr"))+" "),(
                item.identity_value &&
                _vm.getIdentityValueType(item.identity_value) === 'qr'
              )?[_vm._v(" ("+_vm._s(_vm.getIdentityValue(item.identity_value))+") ")]:_vm._e()],2)]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.getIdentityValue(item.identity_value)),expression:"getIdentityValue(item.identity_value)",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopyIdentityValue),expression:"onCopyIdentityValue",arg:"success"}],attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("users.card"))+" "),(
                item.identity_value &&
                _vm.getIdentityValueType(item.identity_value) === 'card'
              )?[_vm._v(" ("+_vm._s(_vm.getIdentityValue(item.identity_value))+") ")]:_vm._e()],2)]):_vm._e()],1)}):_vm._e()]}},{key:"item.pass_type",fn:function(ref){
              var item = ref.item;
return [(item.eventType !== 'deviceAlarm')?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.pass_type === 'before'),expression:"item.pass_type === 'before'"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.recognized")))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.pass_type === 'pass'),expression:"item.pass_type === 'pass'"}]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-all ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.recognizedAndPass")))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.pass_type === 'alarm'),expression:"item.pass_type === 'alarm'"}]},[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-remove-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("users.notRecognized"))),_c('br'),(item.message)?[_vm._v(" "+_vm._s(item.message)+" ")]:_vm._e()],2)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.pass_type === 'error'),expression:"item.pass_type === 'error'"}]},[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("users.error"))),_c('br'),(item.message)?[_vm._v(" "+_vm._s(item.message)+" ")]:_vm._e()],2)])],1)]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        item.pass_type === 'alarm' &&
        item.identity_types &&
        item.identity_types.includes('face')
      ),expression:"\n        item.pass_type === 'alarm' &&\n        item.identity_types &&\n        item.identity_types.includes('face')\n      "}]},[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","color":"red","x-small":""},on:{"click":function($event){return _vm.guestReg(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-account-clock")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("users.guestPass"))+" ")])])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("users.no_passes_registered"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }