var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"fields":_vm.fields,"rows":_vm.data,"click":_vm.onClick,"dblclick":_vm.onDblClick,"rowstyle":_vm.rowstyle},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"50px","border-radius":"50%"},attrs:{"src":item.avatar},on:{"mouseenter":function($event){item.isHasPhoto ? _vm.onHoverImg(item.photo) : null},"mouseleave":_vm.onLeaveHoverImg}})]}},{key:"item.identity_types",fn:function(ref){
var item = ref.item;
return [_c('span',[(
          item.purposeType === 'entrance' || item.purposeType === 'duplex'
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-enter")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])]):_vm._e(),(item.purposeType === 'exit')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rotate-180",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-exit")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])]):_vm._e()],1),_vm._l((item.identity_types),function(type){return _c('span',{key:type},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]):_vm._e()],1)})]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("users.no_passes_registered"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }