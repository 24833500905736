<template>
  <div
    :class="[
      'device-monitoring__item',
      { 'device-monitoring__item--success': isSuccess },
    ]"
    @click="goToDevice"
  >
    <strong>{{ data.device_type }}</strong
    >:
    {{ data.name }}
  </div>
</template>

<script>
const SYNC_DEVICE_EVENT = "syncdevice";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSuccess: false,
    };
  },

  methods: {
    goToDevice() {
      this.$router.push({
        name: "editDevice",
        params: {
          id: this.data.id,
        },
      });
    },

    connectWebSockets() {
      this.$root.$on(SYNC_DEVICE_EVENT, (event) => {
        this.onSyncDevice(event);
      });
    },

    disconnectedWebSockets() {
      this.$root.$off(SYNC_DEVICE_EVENT);
    },

    onSyncDevice(event) {
      if (event.id === this.data.id) {
        this.isSuccess = event.status;
      }
    },
  },

  destroyed() {
    this.disconnectedWebSockets();
  },

  created() {
    this.connectWebSockets();
  },
};
</script>
