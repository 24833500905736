<template>
  <v-row>
    <v-col cols="auto">
      <date-picker
        v-model="dateRange"
        type="datetime"
        range
        :format="DATE_FORMAT"
        value-type="format"
        :placeholder="$ml.get('advanced.dateTime')"
        confirm
        :clearable="false"
        :disabled-date="notBeforeAndAfter100Days"
        @confirm="submit"
        @pick="onPickDate"
        @close="onCloseDatepicker"
      >
        <template v-slot:header>
          {{ $ml.get("advanced.range100days") }}
        </template>
      </date-picker>
    </v-col>

    <v-col cols="auto">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            bordered
            overlap
            :content="identityTypes.length"
            :value="identityTypes.length ? true : false"
          >
            <v-btn v-bind="attrs" v-on="on" small depressed>
              {{ $ml.get("advanced.identityTypes") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card>
          <v-list>
            <v-list-item-group
              v-model="identityTypes"
              multiple
              @change="submit"
            >
              <v-list-item value="qr">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.qr")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="card">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.card")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="face">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.face")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="auto">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            bordered
            overlap
            :content="userType.length"
            :value="userType.length ? true : false"
          >
            <v-btn v-bind="attrs" v-on="on" small depressed>
              {{ $ml.get("users.userType") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card>
          <v-list>
            <v-list-item-group v-model="userType" multiple @change="submit">
              <v-list-item value="employee">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.employee")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="guest">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.guest")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="manager">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.manager")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="auto">
      <v-menu offset-y bottom left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            bordered
            overlap
            :content="selectionSubdivisions.length"
            :value="selectionSubdivisions.length ? true : false"
          >
            <v-btn v-bind="attrs" v-on="on" small depressed>
              {{ $ml.get("users.subdivision") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card max-height="400" max-width="500">
          <v-text-field
            v-model="searchSubdivisions"
            :label="$ml.get('datatable.search')"
            flat
            solo
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          />
          <v-divider class="m-0" />
          <v-list>
            <v-treeview
              v-model="selectionSubdivisions"
              selectable
              :items="subdivisions"
              item-text="name"
              selection-type="all"
              :search="searchSubdivisions"
              :open-all="searchSubdivisions ? true : false"
              @input="submit"
            >
              <template
                v-if="item.is_guest"
                slot="append"
                item-key="id"
                item-text="name"
                slot-scope="{ item }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="mr-2" small
                      >mdi-account-clock</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("subdiv.guest") }}</span>
                </v-tooltip>
              </template>
            </v-treeview>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="auto">
      <v-menu
        offset-y
        bottom
        left
        :max-width="400"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            bordered
            overlap
            :content="selectionDepartments.length"
            :value="selectionDepartments.length ? true : false"
          >
            <v-btn v-bind="attrs" v-on="on" small depressed>
              {{ $ml.get("users.department") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card max-height="400" max-width="500">
          <v-text-field
            v-model="searchDepartments"
            :label="$ml.get('datatable.search')"
            flat
            solo
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          />
          <v-divider class="m-0" />
          <v-list>
            <v-treeview
              v-model="selectionDepartments"
              selectable
              :items="departments"
              item-text="name"
              selection-type="independent"
              :search="searchDepartments"
              :open-all="searchDepartments ? true : false"
              @input="submit"
            />
          </v-list>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="auto">
      <v-switch
        v-model="isOnObject"
        :label="$ml.get('menu.advanced')"
        hide-details
        class="mt-0"
        @change="submit"
      />
    </v-col>
  </v-row>
</template>

<script>
const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export default {
  data() {
    return {
      dateRange: [
        this.$moment().startOf("month").format(DATE_FORMAT),
        this.$moment().endOf("month").format(DATE_FORMAT),
      ],
      pickDate: "",
      isFirstPickDate: true,

      identityTypes: [],
      userType: [],

      subdivisions: [],
      selectionSubdivisions: [],
      searchSubdivisions: "",

      departments: [],
      selectionDepartments: [],
      searchDepartments: "",

      isOnObject: false,

      DATE_FORMAT,
    };
  },

  methods: {
    submit() {
      const data = {
        identityTypes: this.identityTypes,
        userType: this.userType,
        subdivisions: this.selectionSubdivisions,
        departments: this.selectionDepartments,
        isOnObject: this.isOnObject,
      };

      if (this.dateRange[0]) {
        data.startDate = this.$moment(
          this.dateRange[0],
          DATE_FORMAT
        ).toISOString();
      }

      if (this.dateRange[1]) {
        data.endDate = this.$moment(
          this.dateRange[1],
          DATE_FORMAT
        ).toISOString();
      }

      this.$emit("change", data);
    },

    getSubdivisions() {
      this.axios("subdivisions/tree").then((response) => {
        this.subdivisions = response.data.data;
      });
    },

    getDepartments() {
      this.axios("departments/tree").then((response) => {
        this.departments = response.data.data;
      });
    },

    notBeforeAndAfter100Days(day) {
      let isDisabled = false;

      if (this.pickDate) {
        const currentDay = this.$moment(day);
        const pickerDay = this.$moment(this.pickDate);
        const before100Day = this.$moment(pickerDay).add(-99, "days");
        const after100Day = this.$moment(pickerDay).add(99, "days");

        if (
          currentDay.isAfter(after100Day) ||
          currentDay.isBefore(before100Day)
        ) {
          isDisabled = true;
        }
      }

      return isDisabled;
    },

    onPickDate(date) {
      if (this.isFirstPickDate) {
        this.pickDate = date;
        this.isFirstPickDate = false;
      }
    },

    onCloseDatepicker() {
      this.pickDate = "";
      this.isFirstPickDate = true;
    },
  },

  created() {
    this.getSubdivisions();
    this.getDepartments();
    this.submit();
  },
};
</script>
