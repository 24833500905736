<template>
  <DataTable
    :fields="fields"
    :rows="data"
    :dblclick="onDblClick"
    :rowstyle="rowstyle"
  >
    {{ $ml.get("users.no_passes_registered") }}

    <template #[`item.avatar`]="{ item }">
      <img
        :src="item.avatar"
        style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover"
        @mouseenter="item.isHasPhoto ? onHoverImg(item.photo) : null"
        @mouseleave="onLeaveHoverImg"
      />
    </template>

    <template v-slot:footer>
      <slot name="footer" />
      <button
        v-if="data.length"
        class="btn btn-primary add"
        style="float: right"
        @click="closeVisitAll(data)"
      >
        {{ $ml.get("button.closeAllVisits") }}
      </button>
      <button
        name="reportIndoors"
        v-if="data.length"
        class="btn btn-success mdi mdi-file-excel mr-1"
        style="float: right"
        :title="$ml.get('advanced.reportIndoors')"
        @click="reportIndoors"
      ></button>
    </template>
  </DataTable>
</template>

<script>
import DataTable from "@/components/datatable";

export default {
  components: { DataTable },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    rowstyle: Function,
  },

  data() {
    return {
      fields: [
        {
          name: "avatar",
          style: "width: 50px;",
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$ml.get("users.device"),
              style: "font-size: 13px; margin-right: 3px;",
            },
            {
              name: "utype",
              title: this.$ml.get("users.status"),
              style: "font-size: 10px; margin-right: 3px; display: inline",
            },
            {
              name: "company",
              title: this.$ml.get("users.subdivision"),
              btag: " : ",
              style: "font-size: 10px; display: inline",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
        {
          type: "button",
          title: this.$ml.get("users.eventClose"),
          class: "btn btn-success mb-3 mdi mdi-open-in-new",
          click: this.closeVisit,
          style: "width: 40px; margin-left: 2px;",
        },
      ],
    };
  },

  methods: {
    onDblClick(item) {
      this.$emit("dblclick", item);
    },

    closeVisitAll(data) {
      if (data.length) {
        const ids = data.map((item) => item.user_id);

        this.axios
          .put("visit/close", { ids })
          .then(() => {
            this.$emit("closeVisits");
          })
          .catch((error) => {
            let message = error.response.data.status.message;

            switch (message) {
              case "no permission for close visits":
                message = this.$ml.get("users.noRightsToCloseVisitMessage");
                break;
              default:
                break;
            }

            this.$notify({
              group: "info",
              type: "error",
              text: message,
            });
          });
      }
    },

    closeVisit(item) {
      this.closeVisitAll([item]);
    },

    reportIndoors() {
      this.$emit("report");
    },

    onHoverImg(img) {
      this.$emit("hoverImg", img);
    },

    onLeaveHoverImg(img) {
      this.$emit("leaveHoverImg", img);
    },
  },
};
</script>
