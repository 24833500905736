var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.isZoneEdit())?_c('div',{staticClass:"panel mb-5"},[_c('button',{staticClass:"btn btn-primary v-icon mdi mdi-shape-circle-plus mr-1",attrs:{"title":_vm.$ml.get('zone.addNewDeviceZone')},on:{"click":_vm.addZone}}),(_vm.zoneActive.id !== undefined && _vm.zoneActive.id !== null)?_c('button',{staticClass:"btn btn-primary v-icon mdi mdi-circle-edit-outline mr-1",attrs:{"title":_vm.$ml.get('zone.makeChanges')},on:{"click":_vm.editZone}}):_vm._e(),(_vm.zoneActive.id !== undefined && _vm.zoneActive.id !== null)?_c('button',{staticClass:"btn btn-danger v-icon mdi mdi-delete-circle-outline",attrs:{"title":_vm.$ml.get('zone.deleteSelectedZone')},on:{"click":_vm.deleteZone}}):_vm._e()]):_vm._e(),_c('v-card',{staticClass:"mx-auto"},[_c('v-list',[_c('v-treeview',{attrs:{"hoverable":"","items":_vm.tree,"return-object":"","activatable":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('a',{class:'treeitem ' + _vm.setTreeActiveClass(item),staticStyle:{"height":"100%","width":"100%","display":"block","float":"left"},on:{"click":function($event){return _vm.selectZone(item, $event)},"dblclick":_vm.editZone}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.isDeviceControl())?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getSelectCount() > 0),expression:"getSelectCount() > 0"}]},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("menu.actions"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,3037635674)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.emergency('open')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.emergency.open")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.emergency('default')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.emergency.default")))])],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.ogateStatus(['off'])}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.ogatestate.off")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.ogateStatus(['on'])}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.ogatestate.on")))])],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.biometricDisable(true)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.biometric.off")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.biometricDisable(false)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.biometric.on")))])],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.ogateReboot('on')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.reboot")))])],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.onDelete}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.delete")))])],1)],1)],1)],1)]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[(_vm.isDeviceEdit())?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("devices.addDevice"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,3304390294)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":{
                        name: 'addDevice',
                        query: { device: 'OGATE' },
                      }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.addNewDeviceOgate")))])],1),_c('v-list-item',{attrs:{"link":"","to":{
                        name: 'addDevice',
                        query: { device: 'UFACE' },
                      }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.addNewDeviceUface")))])],1),_c('v-list-item',{attrs:{"link":"","to":{
                        name: 'addDevice',
                        query: { device: 'CONTRL' },
                      }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("devices.addNewDeviceController")))])],1)],1)],1)],1):_vm._e()])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('DataTable',{attrs:{"fields":_vm.fields,"rows":_vm.devices.data,"rowstyle":_vm.styleRow,"dblclick":_vm.edit,"select":true},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('vue-pagination',{attrs:{"pagination":_vm.devices.pagination,"offset":2},on:{"paginate":_vm.onChangePage}}),(_vm.isDeviceEdit())?[_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.scan},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}],null,false,1919056595)},[_vm._v(_vm._s(_vm.$ml.get("devices.scan"))+" ")]),(_vm.selectedDevices.length)?_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.addSelectDev}},[_vm._v(_vm._s(_vm.$ml.get("devices.addscandevices"))+" ")]):_vm._e(),(_vm.addresses.length)?[_c('hr'),_c('v-chip-group',{attrs:{"column":"","multiple":""},model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}},_vm._l((_vm.addresses),function(device){return _c('v-chip',{key:device.ip,attrs:{"value":device,"filter":!_vm.allDevices.includes(device.ip),"color":_vm.allDevices.includes(device.ip) ? 'success' : '',"disabled":_vm.isDisabledScanDevice(device),"outlined":""}},[_c('strong',[_vm._v(_vm._s(device.type))]),_vm._v(": "+_vm._s(device.ip)+" "),(device.stream)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.allDevices.includes(device.ip)
                                    ? 'success'
                                    : ''},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showIp(device.ip)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video-account")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("devices.viewStream"))+" ")]):_vm._e()],1)}),1),(_vm.isCameraShow)?_c('CameraBox',{attrs:{"devices":_vm.netips,"showing":_vm.isCameraShow,"showcontrol":false},on:{"toggle":function($event){_vm.isCameraShow = $event}}}):_vm._e()]:_vm._e()]:_vm._e()]},proxy:true},{key:"item.error_logs",fn:function(ref){
                                    var item = ref.item;
return [(item.is_error_logs)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.openDeviceErrorLogs(item)}}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1):_vm._e()]}},{key:"item.gate_command",fn:function(ref){
                                    var item = ref.item;
return _vm._l((item.gate_command),function(gateStatus,index){return _c('div',{key:index,staticClass:"text-no-wrap"},[(item.gate_command.length > 1)?[_vm._v(" "+_vm._s(_vm.$ml.get("devices.door"))+" "+_vm._s(index + 1)+": ")]:_vm._e(),(gateStatus === 'close')?[_vm._v(" "+_vm._s(_vm.$ml.get("devices.doorClose"))+" "),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","x-small":"","depressed":"","outlined":""},on:{"click":function($event){_vm.changeGateCommand(
                            item.id,
                            _vm.getArrayGateCommand(
                              item.gate_command,
                              index,
                              'open'
                            )
                          )}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$ml.get("devices.doorOpening"))+" ")])]:[_vm._v(" "+_vm._s(_vm.$ml.get("devices.doorOpen"))+" "),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","x-small":"","depressed":"","outlined":""},on:{"click":function($event){_vm.changeGateCommand(
                            item.id,
                            _vm.getArrayGateCommand(
                              item.gate_command,
                              index,
                              'close'
                            )
                          )}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$ml.get("devices.doorClosing"))+" ")])]],2)})}}],null,true)},[_vm._v(_vm._s(_vm.$ml.get("devices.notDevices"))+" ")])],1)],1)],1),(_vm.zonedialog)?_c('v-app',[_c('ModalZoneEdit',{attrs:{"zones":_vm.zones,"parent":_vm.zoneActive},on:{"toggle":function($event){return _vm.zoneDialogClose($event)}},model:{value:(_vm.editZoneItem),callback:function ($$v) {_vm.editZoneItem=$$v},expression:"editZoneItem"}})],1):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[(_vm.selectedDeviceErrorLogs)?_c('v-dialog',{attrs:{"value":true,"max-width":"700px"},on:{"click:outside":_vm.closeDeviceErrorLogs}},[_c('ModalViewErrorLogs',{attrs:{"id":_vm.selectedDeviceErrorLogs},on:{"close":_vm.closeDeviceErrorLogs}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }