<template>
  <v-dialog
    v-model="isShow"
    max-width="400px"
    @click:outside="setFromProps"
    class="requests-filter"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="primary"
        bordered
        overlap
        :content="filterCounter"
        :value="filterCounter ? true : false"
      >
        <v-btn v-bind="attrs" v-on="on">{{ $ml.get("requests.filter") }}</v-btn>
      </v-badge>
    </template>

    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $ml.get("requests.filter") }}</v-toolbar-title>
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="reset">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          {{ $ml.get("button.reset") }}
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="requests-filter__save-btn">
              <v-btn v-bind="attrs" v-on="on" icon @click="save">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <div class="requests-filter__save-btn-popup">
                <v-checkbox
                  v-model="isAlwaysSaveFilterState"
                  :label="$ml.get('requests.alwaysSaveFilter')"
                  hide-details
                  light
                />
              </div>
            </div>
          </template>
          {{ $ml.get("button.apply") }}
        </v-tooltip>
      </v-toolbar>

      <v-card-text class="pt-5">
        <h5>{{ $ml.get("requests.status") }}</h5>
        <v-checkbox
          v-model="statusState"
          :label="$ml.get('requests.statusNew')"
          :value="0"
          hide-details
        />
        <v-checkbox
          v-model="statusState"
          :label="$ml.get('requests.statusWait')"
          :value="1"
          hide-details
        />
        <v-checkbox
          v-model="statusState"
          :label="$ml.get('requests.statusDone')"
          :value="2"
          hide-details
        />
        <v-checkbox
          v-model="statusState"
          :label="$ml.get('requests.statusCancel')"
          :value="3"
          hide-details
        />

        <v-divider />

        <h5>{{ $ml.get("requests.user") }}</h5>
        <v-select
          v-model="userTypeState"
          :items="userTypeOptions"
          outlined
          clearable
          hide-details
          :label="$ml.get('requests.userType')"
        />

        <v-divider />

        <h5>{{ $ml.get("requests.pass") }}</h5>
        <v-switch
          v-model="onlyActiveState"
          :label="$ml.get('requests.activePass')"
        />
        <v-select
          v-show="userTypeState === 'guest'"
          v-model="onePassState"
          :items="onePassOptions"
          :label="$ml.get('requests.typePass')"
          outlined
          clearable
          hide-details
        />

        <v-divider />

        <h5>{{ $ml.get("requests.owner") }}</h5>
        <v-select
          v-model="ownerState"
          :items="ownersOptions"
          outlined
          clearable
          hide-details
          :loading="isOwnersListPending"
          :label="$ml.get('requests.name')"
        />

        <v-divider />

        <h5>{{ $ml.get("requests.department") }}</h5>
        <v-select
          v-model="departmentState"
          :items="departmentsOptions"
          outlined
          clearable
          hide-details
          :loading="isDepartmentsListPending"
          :label="$ml.get('requests.department')"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RequestsFilter",

  props: {
    status: {
      type: Array,
    },

    onePass: {
      type: Boolean,
    },

    onlyActive: {
      type: Boolean,
    },

    owner: {
      type: Number,
    },

    userType: {
      type: String,
    },

    department: {
      type: Number,
    },

    defaultState: {
      type: Object,
    },

    isAlwaysSaveFilter: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    userTypeState(userTypeState) {
      if (!userTypeState) {
        this.onePassState = null;
      }
    },
  },

  data() {
    return {
      isAlwaysSaveFilterState: this.isAlwaysSaveFilter,

      isShow: false,

      statusState: this.status,
      onePassState: this.onePass,
      onlyActiveState: this.onlyActive,
      ownerState: this.owner,
      userTypeState: this.userType,
      departmentState: this.department,

      onePassOptions: [
        {
          value: true,
          text: this.$ml.get("requests.onepass"),
        },
        {
          value: false,
          text: this.$ml.get("requests.multiPass"),
        },
      ],

      ownersOptions: [
        {
          value: this.$auth.user().admin_id,
          text: `${this.$auth.user().last_name} ${this.$auth.user().first_name}
            ${this.$auth.user().middle_name}`,
        },
      ],

      isOwnersListPending: false,
      isOwnersListFalse: false,
      isOwnersListSuccess: false,

      userTypeOptions: [
        {
          value: "guest",
          text: this.$ml.get("requests.guest"),
        },
        {
          value: "employee",
          text: this.$ml.get("requests.employee"),
        },
      ],

      departmentsOptions: [],

      isDepartmentsListPending: false,
      isDepartmentsListFalse: false,
      isDepartmentsListSuccess: false,
    };
  },

  methods: {
    setFromProps() {
      this.statusState = this.status;
      this.onePassState = this.onePass;
      this.onlyActiveState = this.onlyActive;
      this.ownerState = this.owner;
      this.userTypeState = this.userType;
      this.departmentState = this.department;
    },

    close() {
      this.isShow = false;
    },

    save() {
      this.close();

      this.$emit("save", {
        status: this.statusState,
        onePass: this.onePassState,
        onlyActive: this.onlyActiveState,
        owner: this.ownerState,
        userType: this.userTypeState,
        department: this.departmentState,
        isAlwaysSaveFilter: this.isAlwaysSaveFilterState,
      });
    },

    reset() {
      this.statusState = this.defaultState.status;
      this.onePassState = this.defaultState.onePass;
      this.onlyActiveState = this.defaultState.onlyActive;
      this.ownerState = this.defaultState.owner;
      this.userTypeState = this.defaultState.userType;
      this.departmentState = this.defaultState.department;
    },

    getOwnersList() {
      this.isOwnersListPending = true;
      this.isOwnersListFalse = false;
      this.isOwnersListSuccess = false;

      this.axios("request/owners")
        .then((response) => {
          this.isOwnersListPending = false;
          this.isOwnersListFalse = false;
          this.isOwnersListSuccess = true;

          const owners = response.data.data.map((owner) => ({
            value: owner.id,
            text: owner.name,
          }));

          this.ownersOptions = [...this.ownersOptions, ...owners];
        })
        .catch(() => {
          this.isOwnersListPending = false;
          this.isOwnersListFalse = true;
          this.isOwnersListSuccess = false;
        });
    },

    getDepartmentsList() {
      this.isDepartmentsListPending = true;
      this.isDepartmentsListFalse = false;
      this.isDepartmentsListSuccess = false;

      this.axios("departments")
        .then((response) => {
          this.isDepartmentsListPending = false;
          this.isDepartmentsListFalse = false;
          this.isDepartmentsListSuccess = true;

          const departments = response.data.data.map((department) => ({
            value: department.id,
            text: department.name,
          }));

          this.departmentsOptions = [
            ...this.departmentsOptions,
            ...departments,
          ];
        })
        .catch(() => {
          this.isDepartmentsListPending = false;
          this.isDepartmentsListFalse = true;
          this.isDepartmentsListSuccess = false;
        });
    },
  },

  computed: {
    filterCounter() {
      let count = 0;

      if (this.status.length) {
        count += 1;
      }

      if (this.onePass !== null) {
        count += 1;
      }

      if (this.onlyActive) {
        count += 1;
      }

      if (this.owner) {
        count += 1;
      }

      if (this.userType) {
        count += 1;
      }

      if (this.department) {
        count += 1;
      }

      return count;
    },
  },

  created() {
    this.getOwnersList();
    this.getDepartmentsList();
  },
};
</script>
