var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('date-picker',{attrs:{"type":"datetime","range":"","format":"DD.MM.YYYY HH:mm","value-type":"format","placeholder":_vm.$ml.get('advanced.dateTime'),"confirm":""},on:{"confirm":_vm.submit,"clear":_vm.submit},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","bordered":"","overlap":"","content":_vm.identityTypes.length,"value":_vm.identityTypes.length ? true : false}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("advanced.identityTypes"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.submit},model:{value:(_vm.identityTypes),callback:function ($$v) {_vm.identityTypes=$$v},expression:"identityTypes"}},[_c('v-list-item',{attrs:{"value":"qr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.qr")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"card"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.card")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"face"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.face")))])],1)]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","bordered":"","overlap":"","content":_vm.type.length,"value":_vm.type.length ? true : false}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("advanced.typeStatus"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)],1)]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.submit},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-list-item',{attrs:{"value":"alarm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.notRecognized")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.recognizedAndPass")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"before"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.recognized")))])],1)]}}])}),_c('v-list-item',{attrs:{"value":"error"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("users.error")))])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }