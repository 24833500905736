var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type)?_c('v-app',[_c('div',{staticClass:"monitoring-fullscreen"},[_c('div',{staticClass:"monitoring-fullscreen__top"},[_c('div',{staticClass:"monitoring-fullscreen__photo",style:({ backgroundImage: ("url(" + _vm.image + ")") })}),_c('div',{staticClass:"monitoring-fullscreen__info"},[_c('span',[_c('div',{staticClass:"monitoring-fullscreen__meta"},[(_vm.identityTypes.length)?_c('div',{staticClass:"mr-2"},_vm._l((_vm.identityTypes),function(type){return _c('span',{key:type,staticClass:"mr-1 ml-1"},[(type === 'face')?[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-emoticon-happy-outline ")]),_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]:_vm._e(),(type === 'qr')?[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-qrcode-scan ")]),_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]:_vm._e(),(type === 'card')?[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-card-bulleted-outline ")]),_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]:_vm._e()],2)}),0):_vm._e(),(_vm.purpose)?_c('div',[(_vm.purpose === 'entrance')?[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"success"}},[_vm._v("mdi-location-enter")]),_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])]:_vm._e(),(_vm.purpose === 'exit')?[_c('v-icon',{staticClass:"rotate-180 mr-1",attrs:{"small":"","color":"error"}},[_vm._v("mdi-location-exit")]),_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])]:_vm._e()],2):_vm._e()]),(_vm.deviceName)?_c('div',{staticClass:"monitoring-fullscreen__zone"},[_vm._v(" "+_vm._s(_vm.zone)+" ("+_vm._s(_vm.deviceName)+") ")]):_vm._e(),(_vm.temperature)?_c('div',{class:[
              'monitoring-fullscreen__temperature',
              {
                'monitoring-fullscreen__temperature--success':
                  _vm.isNormalTemperature,
              },
              {
                'monitoring-fullscreen__temperature--danger':
                  !_vm.isNormalTemperature,
              } ]},[_vm._v(" "+_vm._s(_vm.temperatureWithUnit)+" ")]):_vm._e(),_c('strong',[(_vm.user_id)?_c('router-link',{attrs:{"to":{ name: 'showUser', params: { id: _vm.user_id } }}},[_vm._v(" "+_vm._s(_vm.name)+" ")]):[_vm._v(" "+_vm._s(_vm.name)+" ")]],2),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.createdAt,"DD.MM.YYYY HH:mm:ss"))+" ")])])]),(_vm.typeText)?_c('div',{class:[
        'monitoring-fullscreen__bottom',
        {
          'monitoring-fullscreen__bottom--error': _vm.type === 'error',
        },
        {
          'monitoring-fullscreen__bottom--black': _vm.isBlacked,
        },
        {
          'monitoring-fullscreen__bottom--warning': _vm.type === 'alarm',
        },
        {
          'monitoring-fullscreen__bottom--success':
            _vm.type === 'pass' || _vm.type === 'before',
        } ]},[_vm._v(" "+_vm._s(_vm.typeText)+" ")]):_vm._e()]),_c('v-row',{attrs:{"justify":"center"}},[(_vm.isShowAlarmDialog)?_c('v-dialog',{attrs:{"value":true,"max-width":"700px","persistent":""}},[_c('ModalAlarmDialog',{attrs:{"data":{
          full_name: _vm.name,
          purpose: (_vm.zone + " (" + _vm.deviceName + ")"),
        }},on:{"close":_vm.closeAlarmDialog}})],1):_vm._e()],1)],1):_c('v-app',[_c('div',{staticClass:"monitoring-fullscreen monitoring-fullscreen--center"},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$ml.get("monitoring.nextEvent"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }