<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $ml.get("devices.zoneDevice") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-text-field
                :label="$ml.get('zone.name') + '*'"
                v-model="name"
                :rules="nameRules"
                required
              />

              <v-select
                return-object
                item-text="name"
                item-value="id"
                :items="zones"
                dblclick
                v-model="thisparent"
                :label="$ml.get('zone.parent')"
              />

              <v-switch
                v-model="isTimeTracking"
                :label="$ml.get('zone.is_time_tracking')"
              />

              <v-text-field
                :label="$ml.get('zone.guestCard')"
                :value="guestCard"
                :rules="guestCardRules"
                required
                @input="guestCard = $event.toUpperCase()"
              />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
          <v-btn color="primary" text @click="store">{{
            $ml.get("button.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const hexRegex = new RegExp("/[0-9A-Fa-f]/g");

export default {
  props: ["value", "zones"],
  data() {
    return {
      valid: true,

      dialog: true,
      thisparent: null,
      name: "",
      id: 0,
      nameRules: [(v) => !!v || this.$ml.get("message.NameIsRequired")],
      isTimeTracking: false,
      isEntrance: false,
      guestCard: "",
      guestCardRules: [
        (v) =>
          v.length <= 16 ||
          this.$ml.with("length", 16).get("message.lengthIsLarge"),

        (v) =>
          (function () {
            let result = true;

            if (v.length && v.match(/[0-9A-F]/g).length !== v.length) {
              result = false;
            }

            return result;
          })() || this.$ml.get("message.isHex"),
      ],
    };
  },
  methods: {
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (!this.$refs.form.validate()) return;
      let url = "/zone/add";
      let data = {
        name: this.name,
        data: {
          is_time_tracking: this.isTimeTracking,
          is_entrance: this.isEntrance,
          guest_card_code: this.guestCard,
        },
        parent_id: this.thisparent.id,
      };

      if (this.id) {
        data.id = this.id;
        url = "/zone/" + this.id;
        this.axios
          .put(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          });
      } else {
        this.axios
          .post(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          });
      }
    },
  },
  mounted() {
    this.id = this.value.id;
    this.name = this.value.name;
    this.isTimeTracking = this.value.data?.is_time_tracking || false;
    this.isEntrance = this.value.data?.is_entrance || false;
    this.guestCard = this.value.data?.guest_card_code || "";
    this.zones.splice(0, 0, {
      id: null,
      parent_id: null,
      name: this.$ml.get("zone.notParent"),
    });
    //this.zones.find()
    if (this.id) {
      let item = this.zones.find((it) => it.id == this.id);
      let index = this.zones.indexOf(item);
      if (index != -1) {
        this.zones.splice(index, 1);
      }
    }

    this.thisparent = {
      id: this.value.parent_id,
      parent_id: null,
      name: "",
    };
  },
};
</script>
