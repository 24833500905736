var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('PassedFilter',{on:{"change":_vm.onChangeFilter}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","loading":_vm.isReportPending,"disabled":_vm.isReportPending}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("advanced.allEvents"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.reportWorkTime}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.worktime")))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("advanced.onlyZoneWithWorktime")))])])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.reportIOTime}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.iotime")))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("advanced.onlyZoneWithWorktime")))])])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.reportPassUsers}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.passUsers")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.reportPassed}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.passed")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.reportPassedTemp}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.passedTemp")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.reportStat}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("advanced.analytic")))])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"fields":_vm.fields,"rows":_vm.data,"searchfn":_vm.onSearch,"dblclick":_vm.showUser,"rowstyle":_vm.styleRow,"searchParams":_vm.searchParams},on:{"sort":_vm.onSort},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('vue-pagination',{attrs:{"pagination":_vm.pagination,"offset":2},on:{"paginate":_vm.getVisits}})]},proxy:true},{key:"item.identity_types",fn:function(ref){
var item = ref.item;
return [(item.purpose)?_c('span',[(item.purpose === 'entrance' || item.purpose === 'duplex')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-enter")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])]):_vm._e(),(item.purpose === 'exit')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rotate-180",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-exit")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])]):_vm._e()],1):_vm._e(),(item.data.identity_types)?_vm._l((item.data.identity_types.split(',')),function(type){return _c('span',{key:type},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]):_vm._e()],1)}):_vm._e()]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('Avatar',{attrs:{"src":("/avatar/small/" + (item.photo)),"type":item.type_user}}):(item.data.image_encode)?_c('Avatar',{attrs:{"src":("data:image/png;base64," + (item.data.image_encode)),"type":item.type_user}}):_c('Avatar',{attrs:{"src":"/avatar/small/avatar.png","type":item.type_user}})]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.full_name || _vm.$ml.get("users.unrecognized"))+" ")]),_c('small',[_vm._v(" "+_vm._s(item.data.zone)+" / "+_vm._s(item.data.device_name)+" ")])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.organization && item.organization.length)?[_vm._v(" "+_vm._s(item.organization.map(function (item) { return item.name; }).join(" / "))+" ")]:_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD.MM.YY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"HH:mm:ss"))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("advanced.nopass"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }