<template>
  <v-row>
    <v-col cols="auto">
      <date-picker
        v-model="dateRange"
        type="datetime"
        range
        format="DD.MM.YYYY HH:mm"
        value-type="format"
        :placeholder="$ml.get('advanced.dateTime')"
        confirm
        @confirm="submit"
        @clear="submit"
      />
    </v-col>

    <v-col cols="auto">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            bordered
            overlap
            :content="identityTypes.length"
            :value="identityTypes.length ? true : false"
          >
            <v-btn v-bind="attrs" v-on="on" small depressed>
              {{ $ml.get("advanced.identityTypes") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card>
          <v-list>
            <v-list-item-group
              v-model="identityTypes"
              multiple
              @change="submit"
            >
              <v-list-item value="qr">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.qr")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="card">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.card")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="face">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.face")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>

    <v-col cols="auto">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="primary"
            bordered
            overlap
            :content="type.length"
            :value="type.length ? true : false"
          >
            <v-btn v-bind="attrs" v-on="on" small depressed>
              {{ $ml.get("advanced.typeStatus") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-card>
          <v-list>
            <v-list-item-group v-model="type" multiple @change="submit">
              <v-list-item value="alarm">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.notRecognized")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="pass">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.recognizedAndPass")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="before">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.recognized")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-list-item value="error">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" color="primary" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("users.error")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dateRange: [],
      identityTypes: [],
      type: [],
    };
  },

  methods: {
    submit() {
      const data = {
        identityTypes: this.identityTypes,
        type: this.type,
      };

      if (this.dateRange[0]) {
        data.startDate = this.$moment(
          this.dateRange[0],
          "DD.MM.YYYY HH:mm"
        ).toISOString();
      }

      if (this.dateRange[1]) {
        data.endDate = this.$moment(
          this.dateRange[1],
          "DD.MM.YYYY HH:mm"
        ).toISOString();
      }

      this.$emit("change", data);
    },
  },
};
</script>
