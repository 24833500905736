<template>
  <div class="image-column">
    <div ref="imagebox" class="image-box">
      <img for="manual-photo" ref="imageSrc" :src="urlPhoto" alt="photo" />
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        name="manual-photo"
        id="manual-photo"
        @change="readURL($event)"
      />
    </div>
    <!-- <a v-if="isAnyCameraEnabled" class="change-photo" href="#" id="take-photo">{{buttonTitle}}</a> -->

    <div style="margin-top: 10px" class="button-box">
      <!-- <label class="label-link" for="manual-photo">{{$ml.get('imageBox.setAvatarFile')}}</label> -->
      <div class="row" style="display: flex">
        <div class="col-md-4">
          <button
            v-if="isCameraEnable"
            class="white-link-button photo-btn"
            @click="webcam"
            :title="$ml.get('ibox.webcam')"
          >
            <img src="~@/assets/img/icons8-webcam2.png" />
          </button>
        </div>
        <div class="col-md-4">
          <button
            class="white-link-button photo-btn"
            :title="$ml.get('ibox.camera')"
            @click="camera"
          >
            <img src="~@/assets/img/icons8-device.png" />
          </button>
        </div>
        <div class="col-md-4">
          <label
            class="white-link-button photo-btn"
            for="manual-photo"
            :title="$ml.get('ibox.file')"
          >
            <img src="~@/assets/img/icons8-file-photo.png" />
          </label>
        </div>
      </div>
    </div>
    <template v-if="isCameraShow">
      <CameraBox
        :devices="devices"
        @toggle="isCameraShow = $event"
        @image="setImage($event)"
      />
    </template>
    <template v-if="isWebCamShow">
      <WebCamBox
        @toggle="isWebCamShow = $event"
        @image="setImage($event)"
        :showing="isWebCamShow"
      />
    </template>
  </div>
</template>

<script>
import CameraBox from "./CameraBox";
import WebCamBox from "./WebCamBox";

//import Cropper from "cropperjs";
import imageResize from "../plugins/imageResize";

//import {hideTakePhotoButton} from "../../js_/components/TakePhotoButton";

export default {
  components: { CameraBox, WebCamBox },
  props: ["photo", "devices", "feature", "newphoto"],
  name: "ImageBox",
  data() {
    return {
      imageObject: {},
      isAnyCameraEnabled: true,
      isCameraShow: false,
      isWebCamShow: false,
      fullHeight: 326,
    };
  },
  methods: {
    camera() {
      this.isCameraShow = true;
      this.isWebCamShow = false;
    },
    webcam() {
      this.isCameraShow = false;
      this.isWebCamShow = true;
    },
    readURL(input) {
      let _this = this;
      if (input.target.files && input.target.files[0]) {
        imageResize
          .fromFile({ file: input.target.files[0], maxSize: 800 })
          .then((resizedImage) => {
            let crypto = resizedImage; //URL.createObjectURL(resizedImage);
            _this.$refs.imageSrc.src = crypto;
            _this.$emit("toggle", crypto);
          });
        _this.errorfile = false;
      }
    },
    setImage(event) {
      let _this = this;
      imageResize.fromSrc({ src: event, maxSize: 800 }).then((resizedImage) => {
        let crypto = resizedImage; //URL.createObjectURL(resizedImage);
        _this.$refs.imageSrc.src = crypto;
        _this.$emit("toggle", crypto);
      });

      //this.$refs.imageSrc.src = crypto;
      //this.$emit("toggle", crypto);
    },
    getFeatureImage(file) {
      let _this = this;
      let formData = new FormData();
      formData.append("name", "image");
      formData.append("image", file);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
        timeout: 20000,
      };
      this.axios.post("face/check", formData, config).then(function (response) {
        if (response.status == 200) {
          var reader = new FileReader();
          reader.onload = function (e) {
            _this.$refs.imageSrc.src = e.target.result;
            _this.$emit("newphoto", e.target.result);
          };
          reader.readAsDataURL(file);
          this.feature = response.data.feature;
          this.newphoto = response.data.filename;

          //_this.$refs.imageSrc.src = getBase64(file. response.data.image);
        } else {
          _this.$refs.imageSrc.src = require("@/assets/img/default_avatar@2x.png");
        }
      });
    },
  },
  computed: {
    urlPhoto() {
      return this.photo
        ? "/avatar/profile/" + this.photo
        : require("@/assets/img/default_avatar@2x.png");
    },
    buttonTitle() {
      return this.imageObject
        ? this.$ml.get("imageBox.updatePhoto")
        : this.$ml.get("imageBox.createPhoto");
    },

    isCameraEnable() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return false;
      }

      return true;
    },
  },

  created() {
    if (this.newphoto) {
      this.setImage(this.newphoto);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables.scss";

.button-box {
  display: flex;
  text-align: justify;
}

.button-box .blue-link-button {
  width: 125px;
  margin: 10px;
  height: auto;
  padding: 0;
  line-height: 44px;
  float: left;
  display: block;
}
.image-column {
  width: 100%;
  text-align: center;
}

.image-column .image-box {
  width: 100%;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;
}

.image-column .image-box img {
  border-radius: 8px;
  width: 100%;
}
.image-column #manual-photo {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.image-column .change-photo {
  font-family: "font-family-roboto";
  font-size: 15px;
  text-align: center;
  color: $primary;
  border-bottom: 1px solid rgba(24, 117, 240, 0.25);
  display: inline-block;
  margin-top: 20px;
}
.image-column .change-photo:hover {
  color: #115bbc;
  border-bottom: 1px solid #115bbc;
  text-decoration: none;
}
.photo-btn {
  width: 100%;
  border: 1px solid $primary;
}
.photo-btn img {
  height: 80%;
}
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 960px) {
}
@media screen and (max-width: 860px) {
}

@media screen and (max-width: 680px) {
}
</style>
