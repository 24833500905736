<template>
  <v-app class="p-3">
    <v-skeleton-loader v-if="isPending" type="card-avatar" />

    <v-form v-if="isSuccess" ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col :cols="12" :sm="4">
          <ImageBox
            v-on:toggle="togglePhoto($event)"
            :devices="devices"
            :photo="photo"
          />
        </v-col>
        <v-col :cols="12" :sm="8">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <v-row>
            <v-col cols="12">
              <!-- TODO: вернуть в следующем релизе -->
              <!-- <v-switch
                v-model="user_type"
                true-value="admin"
                false-value="guard"
                :label="$ml.get('user.admin')"
                :disabled="isSuperAdmin"
              /> -->
              <FullName
                :surname="last_name"
                :name="first_name"
                :patronymic="middle_name"
                @change="onChangeName"
              />
              <!-- <vue-tel-input-vuetify
                v-model="phone"
                outlined
                :label="$ml.get('user.phone')"
                :rules="phoneRules"
                :preferredCountries="[
                  'RU',
                  'BY',
                  'KZ',
                  'MD',
                  'AM',
                  'AZ',
                  'GE',
                  'KG',
                  'TJ',
                  'TM',
                  'UZ',
                ]"
                placeholder=""
                @input="inputPhone"
              /> -->
              <label v-if="user_type != 'admin'">{{
                $ml.get("guard.zones")
              }}</label>
              <tree-select
                v-if="user_type != 'admin'"
                v-model="zone"
                :multiple="true"
                :options="zones"
                :placeholder="$ml.get('guard.zones')"
                :disable-branch-nodes="true"
                :searchable="true"
                :show-count="true"
              />
            </v-col>
          </v-row>
          <v-row v-if="user_type != 'admin'">
            <v-col cols="12">
              <label>{{ $ml.get("guard.permissions") }}</label>
              <tree-select
                v-if="user_type != 'admin'"
                v-model="roles"
                :multiple="true"
                :options="permissions"
                :placeholder="$ml.get('guard.permissions')"
                :disable-branch-nodes="true"
                :searchable="false"
                :show-count="true"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="login-data col-sm-6">
              <h6>{{ $ml.get("auth.logininformation") }}</h6>
              <template v-if="!id || isAuth">
                <v-text-field
                  v-if="!id"
                  v-model="username"
                  :rules="usernameRules"
                  :label="`${$ml.get('auth.username')} *`"
                  outlined
                  :readonly="id > 0"
                  required
                />
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="`${$ml.get('auth.password')} *`"
                  type="password"
                  outlined
                  required
                />
                <v-text-field
                  v-if="password"
                  ref="passwordConfirmation"
                  class="password-guard"
                  v-model="password_confirmation"
                  :rules="passwordConfirmRules"
                  :label="`${$ml.get('auth.passwordConfirm')} *`"
                  type="password"
                  outlined
                  required
                />
              </template>
              <button
                v-if="!isAuth && id"
                class="btn btn-primary change-password-btn"
                @click="isAuth = true"
              >
                {{ $ml.get("guard.changeAuth") }}
              </button>
            </v-col>
          </v-row>

          <v-row justify="end" class="mt-5">
            <v-col cols="auto">
              <v-btn @click="goCancel">
                {{ $ml.get("button.back") }}
              </v-btn>
            </v-col>
            <v-spacer />
            <template v-if="id && user_type != 'admin'">
              <v-col v-if="!deleted" cols="auto">
                <v-btn color="error" @click.prevent="deleteUser">
                  {{ $ml.get("button.delete") }}
                </v-btn>
              </v-col>
            </template>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :loading="isPendingStore"
                :disabled="isPendingStore"
                @click="storeUser"
              >
                {{ $ml.get("button.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-app>
</template>

<script>
import ImageBox from "@/components/ImageBox";
import router from "@/router";
import FullName from "@/components/fullname";
import TreeSelect from "@riophae/vue-treeselect";
import faceValidate from "@/mixins/faceValidate";

const PERMISSION_USER_ADD = "useradd";
const PERMISSION_USER_EDIT = "useredit";
const PERMISSION_USER_DELETE = "userdel";
const PERMISSION_MANAGER_CREATE = "managercreate";
const PERMISSION_REQUEST_VIEW = "reqview";
const PERMISSION_REQUEST_CREATE_GUEST = "requests";
const PERMISSION_REQUEST_CREATE_EMPLOYEE = "requestsemployee";
const PERMISSION_REQUEST_CONFIRM = "reqconfirm";
const PERMISSION_REQUEST_CREATE_USER = "reqcreateuser";
const PERMISSION_DEVICE_CONTROL = "devicecontrol";
const PERMISSION_DEVICE_EDIT = "deviceedit";
const PERMISSION_SUBDIVISIONS_EDIT = "sdvedit";
const PERMISSION_JOURNAL_VIEW = "journal";
const PERMISSION_ZONES_EDIT = "zoneedit";
const PERMISSION_DEPARTMENTS_ADD = "depadd";
const PERMISSION_DEPARTMENTS_EDIT = "depedit";
const PERMISSION_DEPARTMENTS_DELETE = "depdel";
const PERMISSION_SETTINGS_VIEW = "adminedit";

export default {
  mixins: [faceValidate],

  name: "GuardEdit",

  components: { ImageBox, TreeSelect, FullName },

  data() {
    return {
      // isValidPhone: true,
      valid: true,

      isPendingStore: false,
      isSuccessStore: false,
      isFailureStore: false,

      isPending: false,
      isSuccess: false,
      isFailure: false,

      id: this.$route.params.id,
      isAuth: false,
      deleted: false,
      user_type: "guard",
      newphoto: "",
      photo: "",
      phone: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      zone: [],
      username: "",
      password: "",
      password_confirmation: "",
      roles: [],
      isSuperAdmin: false,
      zones: [],
      devices: [],
      errorMessage: "",
      usernameRules: [
        (v) =>
          this.validUserName(v) || this.$ml.get("auth.username_char_valid"),
        (v) => v.length >= 6 || this.$ml.get("auth.lenghtn_username_8"),

        //v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      passwordRules: [
        (v) =>
          this.validPassword(v) || this.$ml.get("auth.password_char_valid"),
        (v) => v.length >= 6 || this.$ml.get("auth.lenghtn_password"),
      ],
      passwordConfirmRules: [
        (v) =>
          !!v ||
          this.$ml.get("auth.password") +
            " " +
            this.$ml.get("message.IsRequired"),
        (v) => v == this.password || this.$ml.get("auth.passwords_must_match"),
      ],
      // phoneRules: [
      //   (v) => {
      //     if (!v.length) {
      //       return true;
      //     }
      //     return (
      //       (!/.?[^ 0-9()\-+]/.test(v) && this.isValidPhone) ||
      //       this.$ml.get("user.notPhone")
      //     );
      //   },
      // ],
    };
  },

  computed: {
    cardsAccess() {
      return this.cards;
    },

    permissions() {
      let permissions = [];

      // Пользователи
      permissions = [
        ...permissions,
        { id: PERMISSION_USER_ADD, label: this.$ml.get("permission.useradd") },
        {
          id: PERMISSION_USER_EDIT,
          label: this.$ml.get("permission.useredit"),
        },
        {
          id: PERMISSION_USER_DELETE,
          label: this.$ml.get("permission.userdel"),
        },
      ];

      if (
        this.roles.includes(PERMISSION_USER_EDIT) ||
        this.roles.includes(PERMISSION_USER_DELETE)
      ) {
        permissions = [
          ...permissions,
          {
            id: PERMISSION_MANAGER_CREATE,
            label: this.$ml.get("permission.managercreate"),
          },
        ];
      }

      // Заявки
      permissions = [
        ...permissions,
        {
          id: PERMISSION_REQUEST_VIEW,
          label: this.$ml.get("permission.reqview"),
        },
      ];

      if (this.roles.includes(PERMISSION_REQUEST_VIEW)) {
        permissions = [
          ...permissions,
          {
            id: PERMISSION_REQUEST_CONFIRM,
            label: this.$ml.get("permission.reqconfirm"),
          },
          {
            id: PERMISSION_REQUEST_CREATE_USER,
            label: this.$ml.get("permission.reqcreateuser"),
          },
          {
            id: PERMISSION_REQUEST_CREATE_GUEST,
            label: this.$ml.get("permission.requests"),
          },
        ];
      }

      if (this.roles.includes(PERMISSION_REQUEST_CREATE_GUEST)) {
        permissions = [
          ...permissions,
          {
            id: PERMISSION_REQUEST_CREATE_EMPLOYEE,
            label: this.$ml.get("permission.requestsemployee"),
          },
        ];
      }

      // Устройства
      permissions = [
        ...permissions,
        {
          id: PERMISSION_DEVICE_CONTROL,
          label: this.$ml.get("permission.devicecontrol"),
        },
      ];

      if (this.roles.includes(PERMISSION_DEVICE_CONTROL)) {
        permissions = [
          ...permissions,
          {
            id: PERMISSION_DEVICE_EDIT,
            label: this.$ml.get("permission.deviceedit"),
          },
        ];
      }

      // Другое
      permissions = [
        ...permissions,
        {
          id: PERMISSION_SUBDIVISIONS_EDIT,
          label: this.$ml.get("permission.sdvedit"),
        },
        {
          id: PERMISSION_JOURNAL_VIEW,
          label: this.$ml.get("permission.journal"),
        },
        {
          id: PERMISSION_ZONES_EDIT,
          label: this.$ml.get("permission.zoneedit"),
        },
        {
          id: PERMISSION_SETTINGS_VIEW,
          label: this.$ml.get("permission.adminedit"),
        },
        {
          id: PERMISSION_DEPARTMENTS_ADD,
          label: this.$ml.get("permission.depadd"),
        },
        {
          id: PERMISSION_DEPARTMENTS_EDIT,
          label: this.$ml.get("permission.depedit"),
        },
        {
          id: PERMISSION_DEPARTMENTS_DELETE,
          label: this.$ml.get("permission.depdel"),
        },
      ];

      return permissions;
    },
  },

  methods: {
    // inputPhone(phone, { valid }) {
    //   this.isValidPhone = valid;
    // },

    validUserName(v) {
      var re = /^[a-zA-Z0-9\\-]+$/;
      return re.test(v);
    },

    validPassword(v) {
      var re = /^[^а-яА-Я]*$/;
      return re.test(v);
    },

    validUserConfirmPassword(v) {
      return (
        v == this.password_confirmation || this.password_confirmation == ""
      );
    },

    goCancel() {
      router.push({ name: "guards" });
    },

    togglePhoto(val) {
      this.newphoto = val;
    },

    getZones() {
      this.axios("zones").then((response) => {
        this.zones = response.data.data ? response.data.data : [];
        this.zones.forEach((el) => {
          el.label = el.name;
        });
      });
    },

    getDevices() {
      this.axios("devices").then((response) => {
        this.devices = response.data ? response.data : [];
      });
    },

    deleteUser() {
      if (this.id) {
        this.$dialog
          .confirm(
            `<h3>${this.$ml.get("message.Delete")}</h3>` +
              this.$ml.get("message.confirmDelete"),
            {
              loader: false,
              okText: this.$ml.get("button.delete"),
              cancelText: this.$ml.get("button.cancel"),
              backdropClose: true,
            }
          )
          .then(() => {
            this.axios.delete(`guard/${this.id}`).then(() => {
              router.push({ name: "guards" });
              this.deleted = true;
            });
          });
      }
    },

    getUser() {
      if (this.id) {
        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;

        this.password = "";
        this.password_confirmation = "";
        this.isAuth = false;

        this.axios(`guard/${this.id}`)
          .then((response) => {
            this.isPending = false;
            this.isSuccess = true;
            this.isFailure = false;

            const item = response.data.data;
            this.first_name = item.first_name;
            this.middle_name = item.middle_name;
            this.last_name = item.last_name;

            this.cards = item.cards;
            this.user_type = item.type_user || "guard";
            this.photo = item.photo;
            this.phone = "";
            this.username = item.username;
            this.zone = item.zones;
            this.roles = item.roles;
            this.isSuperAdmin = item.is_admin;

            this.deleted = item.deleted;
          })
          .catch(() => {
            this.isPending = false;
            this.isSuccess = false;
            this.isFailure = true;
          });
      } else {
        this.isPending = false;
        this.isSuccess = true;
        this.isFailure = false;
      }
    },

    storeUser() {
      if (!this.$refs.form.validate()) return;

      this.errorMessage = "";
      this.isPendingStore = true;
      this.isSuccessStore = false;
      this.isFailureStore = false;

      if (this.password && this.password !== this.password_confirmation) {
        return;
      }

      const params = {
        type_user: this.user_type,
        phone: "",
        photo_encrypted: this.newphoto,
        photo: this.photo,
        zones: this.zone,
        username: this.username,
        password: this.password,
        roles: this.roles,
        last_name: this.last_name,
        first_name: this.first_name,
        middle_name: this.middle_name,
      };

      let url = "guard/add";
      if (this.id) {
        url = `guard/${this.id}`;
        params.id = this.id;
      }

      this.axios
        .post(url, params)
        .then(() => {
          router.push({ name: "guards" });

          this.isPendingStore = false;
          this.isSuccessStore = true;
          this.isFailureStore = false;
        })
        .catch((error) => {
          this.errorMessage = this.getLangMessage(
            error.response.data.status.message
          );

          this.isPendingStore = false;
          this.isSuccessStore = false;
          this.isFailureStore = true;
        });
    },

    onChangeName({ surname, name, patronymic }) {
      this.first_name = name;
      this.middle_name = patronymic;
      this.last_name = surname;
    },
  },

  created() {
    this.getUser();
    this.getZones();
    this.getDevices();
  },

  watch: {
    // phone(value) {
    //   this.phone = value;
    // },

    async password() {
      await this.$nextTick();
      if (this.$refs.passwordConfirmation) {
        this.$refs.passwordConfirmation.validate();
      }
    },

    roles(roles) {
      if (roles) {
        // Пользователи
        if (
          !roles.includes(PERMISSION_USER_EDIT) &&
          !roles.includes(PERMISSION_USER_DELETE) &&
          roles.includes(PERMISSION_MANAGER_CREATE)
        ) {
          this.roles = roles.filter(
            (role) => role !== PERMISSION_MANAGER_CREATE
          );
        }

        // Заявки
        if (
          !roles.includes(PERMISSION_REQUEST_VIEW) &&
          roles.includes(PERMISSION_REQUEST_CREATE_GUEST)
        ) {
          this.roles = roles.filter(
            (role) => role !== PERMISSION_REQUEST_CREATE_GUEST
          );
        }

        if (
          !roles.includes(PERMISSION_REQUEST_VIEW) &&
          roles.includes(PERMISSION_REQUEST_CONFIRM)
        ) {
          this.roles = roles.filter(
            (role) => role !== PERMISSION_REQUEST_CONFIRM
          );
        }

        if (
          !roles.includes(PERMISSION_REQUEST_VIEW) &&
          roles.includes(PERMISSION_REQUEST_CREATE_USER)
        ) {
          this.roles = roles.filter(
            (role) => role !== PERMISSION_REQUEST_CREATE_USER
          );
        }

        if (
          !roles.includes(PERMISSION_REQUEST_CREATE_GUEST) &&
          roles.includes(PERMISSION_REQUEST_CREATE_EMPLOYEE)
        ) {
          this.roles = roles.filter(
            (role) => role !== PERMISSION_REQUEST_CREATE_EMPLOYEE
          );
        }

        if (
          !roles.includes(PERMISSION_DEVICE_CONTROL) &&
          roles.includes(PERMISSION_DEVICE_EDIT)
        ) {
          this.roles = roles.filter((role) => role !== PERMISSION_DEVICE_EDIT);
        }
      }
    },
  },
};
</script>
