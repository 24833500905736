<template>
  <div
    :class="[
      'device-monitoring',
      { 'device-monitoring--min-menu': isMenuMinimize },
    ]"
  >
    <v-alert v-if="isFailure && errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <template v-if="isSuccess">
      <Device v-for="device in devices" :key="device.id" :data="device" />
    </template>
  </div>
</template>

<script>
import Device from "./Device.vue";
import { mapState } from "vuex";

export default {
  name: "DeviceMonitoring",

  components: {
    Device,
  },

  data() {
    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      devices: [],
    };
  },

  methods: {
    getDevices() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios("devices")
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;

          this.devices = response.data.data;
        })
        .catch((error) => {
          this.isPending = false;
          this.isFailure = true;
          this.errorMessage = error.response.data.status.errors;
        });
    },
  },

  computed: {
    ...mapState({
      isMenuMinimize: (state) => state.leftMenu.isMenuMinimize,
    }),
  },

  created() {
    this.getDevices();
  },
};
</script>
