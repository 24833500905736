var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('EventsFilter',{on:{"change":_vm.onChangeFilter}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isAllEventsOpen = true}}},[_vm._v(_vm._s(_vm.$ml.get("advanced.allEvents")))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"fields":_vm.fields,"rows":_vm.users,"dblclick":_vm.showUser,"rowstyle":_vm.styleRow,"searchfn":_vm.onSearch,"searchParams":_vm.searchParams},on:{"sort":_vm.onSort},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('vue-pagination',{attrs:{"pagination":_vm.pagination,"offset":2},on:{"paginate":_vm.getUsers}})],1)])]},proxy:true},{key:"item.pass_type",fn:function(ref){
var item = ref.item;
return [(item.data.type === 'before')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.recognized")))])]):_vm._e(),(item.data.type === 'pass')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-all ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.recognizedAndPass")))])]):_vm._e(),(item.data.type === 'alarm')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-remove-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.notRecognized")))])]):_vm._e(),(item.data.type === 'error')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.error")))])]):_vm._e()]}},{key:"item.identity_types",fn:function(ref){
var item = ref.item;
return [(item.purpose)?_c('span',[(item.purpose === 'entrance' || item.purpose === 'duplex')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-enter")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_entrance")))])]):_vm._e(),(item.purpose === 'exit')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rotate-180",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-location-exit")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("device.purpose_exit")))])]):_vm._e()],1):_vm._e(),(item.data.identity_types)?_vm._l((item.data.identity_types.split(',')),function(type){return _c('span',{key:type},[(type === 'face')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon-happy-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.face")))])]):_vm._e(),(type === 'qr')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode-scan ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.qr")))])]):_vm._e(),(type === 'card')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.blacklisted ? 'white' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-bulleted-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("users.card")))])]):_vm._e()],1)}):_vm._e()]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('Avatar',{attrs:{"src":("/avatar/small/" + (item.photo)),"type":item.type_user}}):(item.data.image_encode)?_c('Avatar',{attrs:{"src":("data:image/png;base64," + (item.data.image_encode)),"type":item.type_user}}):(parseInt(item.data.code) === 350)?_c('Avatar',{attrs:{"src":require('@/assets/img/press-btn.svg'),"type":item.type_user}}):(parseInt(item.data.code) >= 301)?_c('Avatar',{attrs:{"src":require('@/assets/img/error.svg'),"type":item.type_user}}):_c('Avatar',{attrs:{"src":"/avatar/small/avatar.png","type":item.type_user}})]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [(item.data.code === '350')?_c('div',[_vm._v(" "+_vm._s(_vm.$ml.get("devices.btnPress"))+" ")]):_c('div',[_vm._v(" "+_vm._s(item.data.name || _vm.$ml.get("users.unrecognized"))+" ")]),_c('small',[_vm._v(" "+_vm._s(item.data.zone)+" / "+_vm._s(item.data.device_name)+" ")])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.message)+" ")]}},{key:"item.identity_value",fn:function(ref){
var item = ref.item;
return [(item.data.identity_value)?[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getIdentityValueIconName(item.data.identity_value)))]),_vm._v(" "+_vm._s(_vm.getIdentityValue(item.data.identity_value))+" ")]:_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD.MM.YY"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"HH:mm:ss"))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("users.no_passes_registered"))+" ")])],1)],1),(_vm.isAllEventsOpen)?_c('ModalAllEventsSettings',{attrs:{"loading":_vm.isAllEventsPending},on:{"close":function($event){_vm.isAllEventsOpen = false},"submit":_vm.allEventsReport}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }