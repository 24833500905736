<template>
  <v-container v-if="loading" class="device-edit-page">
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <v-row v-if="deviceType == 'OGATE' && id > 0">
            <v-spacer />
            <v-col cols="auto">
              <v-btn color="primary" @click="goToPanel">
                {{ $ml.get("devices.goToPanel") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col>
                <div
                  v-if="errorMessage"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ errorMessage }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  :label="`${$ml.get('devices.nameDevice')} *`"
                  outlined
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="ipAddress"
                  :rules="ipRules"
                  :label="`${$ml.get('devices.ipDevice')} *`"
                  :disabled="isEdit"
                  outlined
                  required
                />
              </v-col>
            </v-row>

            <v-row v-if="cardReader !== undefined">
              <v-col>
                <v-switch
                  v-model="cardReader"
                  :label="$ml.get('devices.cardReader')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <DeviceOgate
                  v-if="deviceType == 'OGATE'"
                  v-model="data"
                  :channels="channels"
                  :purpose="channels[0].purpose"
                  @change-channels="channels = $event"
                />
                <DeviceUface
                  v-if="deviceType == 'UFACE'"
                  v-model="data"
                  :channels="channels"
                  :isEdit="id ? true : false"
                  @change-channels="channels = $event"
                />
                <DeviceContr
                  v-if="deviceType == 'CONTRL'"
                  v-model="data"
                  :channels="channels"
                  :id="id"
                  :isEdit="id ? true : false"
                  :ipAddress="ipAddress"
                  @ip="ipAddress = ipAddressFixed = $event"
                  @change-channels="channels = $event"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="auto">
                <v-btn @click.prevent="back">
                  {{ $ml.get("button.back") }}
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col v-if="id" cols="12" sm="auto">
                <v-btn color="error" @click.prevent="remove">
                  {{ $ml.get("button.delete") }}
                </v-btn>
              </v-col>
              <v-col cols="6" sm="auto">
                <v-btn
                  color="primary"
                  :loading="isPending"
                  :disabled="isPending"
                  @click.prevent="store(false)"
                >
                  {{ $ml.get("button.save") }}
                </v-btn>
              </v-col>
              <v-col v-if="id > 0" cols="6" sm="auto">
                <v-btn
                  color="primary"
                  :loading="isPending"
                  :disabled="isPending"
                  @click.prevent="store(true)"
                >
                  {{ $ml.get("button.apply") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-app>

    <v-snackbar v-model="isShowSuccessToast" :timeout="2000" color="success">
      {{ $ml.get("message.saveSuccess") }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isShowSuccessToast = false">
          {{ $ml.get("button.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import DeviceOgate from "./ogate/deviceOgate.vue";
import DeviceUface from "./uface/deviceUface.vue";
import DeviceContr from "./contr/deviceContr.vue";
import { mapState } from "vuex";
import { isIP } from "validator";

export default {
  components: {
    DeviceOgate,
    DeviceUface,
    DeviceContr,
  },

  data() {
    const deviceType = this.$route.query.device;
    const { device, id } = this.$route.params;

    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,

      valid: true,

      isShowSuccessToast: false,

      data: null,
      pdlg: false,
      loading: false,
      channels: [
        {
          channel: 0,
          purpose: "entrance",
          zones_id: null,
          source_id: null,
          enable: true,
        },
      ],
      id: id || 0,
      cardReader: undefined,
      name: device?.ip || "",
      ipAddress: device?.ip || "192.168.0.100",
      ipAddressFixed: "",
      enabled: true,
      mltimeout: 0,
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => v.length <= 20 || "Name must be less than 20 characters",
      ],
      ipRules: [
        (v) => v === "localhost" || isIP(v) || this.$ml.get("message.isIP"),
      ],
      errorMessage: "",

      deviceType,
    };
  },

  watch: {
    async zonesId() {
      await this.$nextTick();
      this.$refs.sources.validate();
    },
  },

  methods: {
    back() {
      this.$router.push({ name: "devices" });
    },

    remove() {
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios
            .delete(`device/${this.id}`)
            .then(() => {
              this.$router.push({ path: `/devices` });
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
            });
        });
    },
    store(isApply) {
      if (this.$refs.form.validate()) {
        this.errorMessage = "";
        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;

        const params = {
          timestamp: moment().unix(),
          name: this.name,
          ip_address: this.ipAddress,
          enabled: this.enabled,
          device_type: this.deviceType,
          channels: this.channels,
          data: JSON.stringify({
            ...this.data,
            card_reader: this.cardReader,
          }),
        };

        if (!this.id) {
          this.axios
            .post("device/add", params)
            .then((response) => {
              if (isApply) {
                this.$router.push({
                  name: "editDevice",
                  params: { id: response.data.data.id },
                });
              } else {
                this.$router.push({ name: "devices" });
              }

              this.isPending = false;
              this.isSuccess = true;
              this.isFailure = false;
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
              this.isPending = false;
              this.isSuccess = false;
              this.isFailure = true;
            });
        } else {
          this.axios
            .put(`device/${this.id}`, params)
            .then(() => {
              if (isApply) {
                this.isShowSuccessToast = true;
              } else {
                this.$router.push({ name: "devices" });
              }

              this.isPending = false;
              this.isSuccess = true;
              this.isFailure = false;
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
              this.isPending = false;
              this.isSuccess = false;
              this.isFailure = true;
            });
        }
      }
    },

    getDevice() {
      if (!this.isEdit) return;

      this.axios(`device/${this.id}`).then((response) => {
        let item = response.data.data;
        this.cardReader = JSON.parse(item.data).card_reader;
        this.name = item.name;
        this.deviceType = item.device_type || "OGATE";
        this.channels = item.channels ? item.channels : this.channels;
        this.ipAddress = this.ipAddressFixed = item.ip_address;
        this.enabled = item.enabled;
        if (item.data != "") {
          this.data = JSON.parse(item.data);
        }
      });
    },
    getChannels() {
      const channels = [];

      const defaultParams = {
        channel: 0,
        enable: true,
        purpose: "entrance",
        zones_id: 1,
        source_id: null,
      };

      const additionalParams = {
        name: "",
        is_fixed_pass: true,
        relay_id: 1,
      };

      switch (this.deviceType) {
        case "CONTRL":
          for (let i = 0; i < 4; i++) {
            channels.push({
              ...defaultParams,
              ...additionalParams,
              channel: i,
              enable: i === 0 ? true : false,
              relay_id: i === 0 || i === 1 ? 1 : 2,
            });
          }
          break;

        default:
          channels.push({
            ...defaultParams,
          });
          break;
      }

      this.channels = channels;
    },

    goToPanel() {
      window.open(`http://${this.ipAddressFixed}:4011`, "_blank");
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
    }),

    isEdit() {
      return this.id > 0;
    },
  },

  created() {
    this.getChannels();
    this.getDevice();
    this.loading = true;
  },
};
</script>
