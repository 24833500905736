<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-select
        v-model="callbackMode"
        :items="callbackItems"
        :label="$ml.get('devices.callbackMode')"
        outlined
        @input="inputValue"
      />
    </v-col>
    <v-col v-if="callbackMode === 'custom'" cols="12" md="6">
      <v-text-field
        v-model="ipAddressCallback"
        :rules="callbackIpRules"
        :label="`${$ml.get('devices.ipCallback')} *`"
        outlined
        required
        @input="inputValue"
      />
    </v-col>

    <v-col
      v-for="(channel, index) of channels"
      :key="channel.channel"
      cols="12"
    >
      <Channel :channel="channel" @change="updateChannels($event, index)" />
    </v-col>

    <v-col v-show="!isEdit">
      <v-text-field
        v-model="pass"
        :label="`${$ml.get('devices.pass')} *`"
        type="password"
        outlined
        required
        :rules="[(v) => !!v || $ml.get('message.NameIsRequired')]"
        @change="inputValue"
      />
    </v-col>

    <template v-if="isEdit">
      <UfaceForm v-if="isUface" v-model="form" @change="inputValue" />
      <UfaceXForm v-if="isUfaceX" v-model="form" @change="inputValue" />
    </template>
  </v-row>
</template>

<script>
import Channel from "../Channel";
import UfaceForm from "./forms/uface";
import UfaceXForm from "./forms/ufaceX";

import { isURL } from "validator";

export default {
  components: {
    Channel,
    UfaceForm,
    UfaceXForm,
  },

  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      reqired: true,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      settingTab: null,

      pass: this.value?.pass || "",
      deviceVersion: this.value?.device_version || "",
      callbackMode: this.value?.ip_address_callback ? "custom" : "default",
      ipAddressCallback: this.value?.ip_address_callback || "",
      form: this.value,

      callbackItems: [
        { text: this.$ml.get("devices.callbackDefault"), value: "default" },
        { text: this.$ml.get("devices.callbackCustom"), value: "custom" },
      ],

      callbackIpRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => isURL(v) || this.$ml.get("message.isCallbackIP"),
      ],
    };
  },

  watch: {
    callbackMode() {
      if (this.callbackMode === "default") {
        this.ipAddressCallback = "";

        this.inputValue();
      }
    },
  },

  methods: {
    inputValue() {
      const { pass, form } = this;

      const data = {
        pass,
        device_type: "OGATEMINI",
        ...form,
      };

      if (this.ipAddressCallback) {
        data.ip_address_callback = this.ipAddressCallback;
      }

      this.$emit("input", data);
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      this.$emit("change-channels", channels);
    },
  },

  computed: {
    isUface() {
      if (!this.deviceVersion || this.deviceVersion.slice(0, 2) === "GD")
        return true;

      return false;
    },

    isUfaceX() {
      if (this.deviceVersion.slice(0, 2) === "OS") return true;

      return false;
    },
  },
};
</script>
