<template>
  <div id="app">
    <notifications group="info" position="top right" />
    <vue-extend-layouts v-if="$auth.check()" />
    <Login v-else />

    <div>
      <ReloadApp :show="isNewVersion" />
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login";
import VueExtendLayouts from "vue-extend-layout";
import ReloadApp from "@/components/ReloadApp";
import { mapState } from "vuex";

export default {
  components: { ReloadApp, Login, VueExtendLayouts },

  data() {
    return {
      isNewVersion: false,
    };
  },

  watch: {
    $route(to) {
      var roles = to.meta.roles != undefined ? to.meta.roles : [];
      var isRole = false;
      if (roles.length > 0) {
        roles.forEach((role) => {
          let roles = this.$auth.user().roles || [];
          if (roles.includes(role)) {
            isRole = true;
            return;
          }
        });
      }
      if (
        to.meta.permission != undefined &&
        to.meta.permission != this.$auth.user().type_user &&
        !isRole
      ) {
        this.$router.push("/");
      }
      if (to.name == "monitoring" && this.$auth.user().type_user == "manager") {
        this.$router.push({ name: "requests" });
      }
      if (!this.$auth.user().password_upd && to.name !== "editPersonal") {
        this.$router.push({
          name: "editPersonal",
          params: { id: this.$auth.user().id },
        });
      }

      if (to.name === "editUser" && this.$auth.user().id == to.params.id) {
        this.$router.push({
          name: "showUser",
          params: { id: this.$auth.user().id },
        });
      }
    },
  },

  methods: {
    async getSettings(isFirstLoad) {
      const version = this.settings?.version;

      await this.$store.dispatch("settings/get");

      this.$vuetify.lang.current = this.settings.lang;
      this.$moment.locale(this.settings.lang);
      this.$ml.change(this.settings.lang);

      if (!isFirstLoad) {
        if (this.settings.version !== version) {
          this.isNewVersion = true;
        } else {
          this.isNewVersion = false;
        }
      }
    },

    websocketCreate() {
      let prot = "ws://";
      if (window.location.protocol == "https:") {
        prot = "wss://";
      }
      window.$ws = new WebSocket(
        `${prot}${window.location.host}/websocket?token=${localStorage.getItem(
          "auth_token_default"
        )}`
      );
      window.$ws.onmessage = (event) => {
        // websocket
        try {
          const msg = JSON.parse(event.data);
          this.$root.$emit(msg.Event, msg.Args);
        } catch (error) {
          return;
        }
      };

      window.$ws.onclose = () => {
        setTimeout(() => {
          this.websocketCreate(true);
        }, 1000);
      };
    },

    onMenuSizeToggle() {
      this.isMenuMinimize = !this.isMenuMinimize;

      localStorage.setItem("isMenuMinimize", this.isMenuMinimize);
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },

  mounted() {
    this.getSettings(true);

    setInterval(() => {
      this.getSettings();
    }, 600000); // получаем настройки каждые 10 минут чтобы проверить актуальность версии

    this.websocketCreate();
  },
};
</script>

<style lang="scss">
@import "assets/sass/app.scss";
</style>
