<template>
  <div class="zoom-img">
    <img :src="url" class="zoom-img__img" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
};
</script>
