var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"p-3"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('RequestsFilter',{attrs:{"status":_vm.status,"onePass":_vm.onePass,"onlyActive":_vm.onlyActive,"owner":_vm.owner,"userType":_vm.userType,"department":_vm.department,"defaultState":_vm.defaultFilterState,"isAlwaysSaveFilter":_vm.isAlwaysSaveFilter},on:{"save":_vm.onChangeFilter}})],1)],1)],1),(
        _vm.$auth.user().type_user == 'admin' ||
        _vm.$auth.user().roles.includes('requests') ||
        _vm.$auth.user().roles.includes('requestsemployee')
      )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{attrs:{"justify":"start","justify-md":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$ml.get("button.add"))+"  "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,2813706328)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":{
                  name: 'addRequest',
                  params: { prevQuery: this.$route.query },
                }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("requests.oneRequest")))])],1),_c('v-list-item',{attrs:{"link":"","to":{
                  name: 'addMultiRequest',
                  params: { prevQuery: this.$route.query },
                }}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$ml.get("requests.multiRequest")))])],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',[(_vm.isPending && !_vm.data.length)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-tbody"}}):_vm._e(),_c('Datatable',{attrs:{"dblclick":_vm.goToEdit,"fields":_vm.fields,"searchfn":_vm.searchfn,"rows":_vm.data,"rowstyle":_vm.rowStyle,"searchParams":_vm.searchParams,"sortName":_vm.sortName,"sortOrder":_vm.sortOrder},on:{"sort":_vm.onSort},scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"footer",fn:function(){return [_c('vue-pagination',{attrs:{"pagination":_vm.pagination,"offset":2},on:{"paginate":_vm.onChangePage}})]},proxy:true},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [(item.contacts.user_type === 'guest')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-clock ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.guest")))])]):_vm._e(),(item.contacts.user_type === 'employee')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-briefcase ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.employee")))])]):_vm._e(),(item.guest_id && item.status !== 4)?[_c('router-link',{attrs:{"to":{ name: 'showUser', params: { id: item.guest_id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]:[_vm._v(" "+_vm._s(item.name)+" ")]]}},{key:"item.statusText",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorByStatus(item.status),"outlined":""}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(item.statusText)+" ")]),_c('v-icon',{staticClass:"d-inline d-sm-none"},[_vm._v(" "+_vm._s(_vm.getIconByStatus(item.status))+" ")])],1)]}},{key:"item.departmentName",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.department_name)+" "),_c('br'),(
              _vm.$auth.user().type_user == 'admin' ||
              _vm.$auth.user().type_user == 'guard'
            )?_c('small',{staticClass:"grey--text lighten-1--text"},[_vm._v(" "+_vm._s(item.owner_name)+" ")]):_vm._e()]}},{key:"item.due",fn:function(ref){
            var item = ref.item;
return [_c('small',[(item.due_from)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-calendar-start ")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.due_from,"DD.MM.YYYY HH:mm"))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.startDue")))])])],1):_vm._e(),(item.due_to)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[{ 'red--text': _vm.$moment().isAfter(item.due_to) }]},'div',attrs,false),on),[_c('v-icon',{class:[
                        { 'red--text': _vm.$moment().isAfter(item.due_to) } ],attrs:{"small":""}},[_vm._v(" mdi-calendar-end ")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.due_to,"DD.MM.YYYY HH:mm"))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ml.get("requests.endDue"))+" "),(_vm.$moment().isAfter(item.due_to))?_c('span',[_vm._v(" ("+_vm._s(_vm.$ml.get("requests.overdue"))+") ")]):_vm._e()])])],1):_vm._e()])]}},{key:"item.requestType",fn:function(ref){
                      var item = ref.item;
return [(item.requestType.includes(1))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-link-variant ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.byLink")))])]):_vm._e(),(item.requestType.includes(0))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-tablet ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.byKiosk")))])]):_vm._e()]}},{key:"item.requestConfirm",fn:function(ref){
                      var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[(_vm.isRequestConfirm(item.status))?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.confirmRequest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.confirmRequest")))])])],1):_vm._e(),_c('v-spacer'),(_vm.isRequestCancel(item))?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.cancelRequest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("requests.cancelRequest")))])])],1):_vm._e()],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$ml.get("requests.notRequests"))+" ")]),(_vm.isFailure)?_c('v-alert',{attrs:{"border":"top","color":"red lighten-2","dark":""}},[_vm._v(" "+_vm._s(_vm.$ml.get("message.errorLoading"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }