<template>
  <div>
    <v-row>
      <v-col cols="9">
        <PassedFilter @change="onChangeFilter" />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              :loading="isReportPending"
              :disabled="isReportPending"
            >
              {{ $ml.get("advanced.allEvents") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item link @click="reportWorkTime">
              <v-list-item-content>
                <v-list-item-title>{{
                  $ml.get("advanced.worktime")
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip left max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                      >mdi-information</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("advanced.onlyZoneWithWorktime") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item link @click="reportIOTime">
              <v-list-item-content>
                <v-list-item-title>{{
                  $ml.get("advanced.iotime")
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip left max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                      >mdi-information</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("advanced.onlyZoneWithWorktime") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item link @click="reportPassUsers">
              <v-list-item-title>{{
                $ml.get("advanced.passUsers")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="reportPassed">
              <v-list-item-title>{{
                $ml.get("advanced.passed")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="reportPassedTemp">
              <v-list-item-title>{{
                $ml.get("advanced.passedTemp")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="reportStat">
              <v-list-item-title>{{
                $ml.get("advanced.analytic")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <DataTable
          :fields="fields"
          :rows="data"
          :searchfn="onSearch"
          :dblclick="showUser"
          :rowstyle="styleRow"
          :searchParams="searchParams"
          @sort="onSort"
        >
          {{ $ml.get("advanced.nopass") }}
          <template v-slot:footer>
            <vue-pagination
              :pagination="pagination"
              :offset="2"
              @paginate="getVisits"
            />
          </template>

          <template #[`item.identity_types`]="{ item }">
            <span v-if="item.purpose">
              <v-tooltip
                v-if="item.purpose === 'entrance' || item.purpose === 'duplex'"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="success"
                    >mdi-location-enter</v-icon
                  >
                </template>
                <span>{{ $ml.get("device.purpose_entrance") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.purpose === 'exit'" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="error"
                    class="rotate-180"
                    >mdi-location-exit</v-icon
                  >
                </template>
                <span>{{ $ml.get("device.purpose_exit") }}</span>
              </v-tooltip>
            </span>

            <template v-if="item.data.identity_types">
              <span
                v-for="type in item.data.identity_types.split(',')"
                :key="type"
              >
                <v-tooltip v-if="type === 'face'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.blacklisted ? 'white' : ''"
                    >
                      mdi-emoticon-happy-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.face") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'qr'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.blacklisted ? 'white' : ''"
                    >
                      mdi-qrcode-scan
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.qr") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'card'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.blacklisted ? 'white' : ''"
                    >
                      mdi-card-bulleted-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.card") }}</span>
                </v-tooltip>
              </span>
            </template>
          </template>

          <template #[`item.avatar`]="{ item }">
            <Avatar
              v-if="item.photo"
              :src="`/avatar/small/${item.photo}`"
              :type="item.type_user"
            />
            <Avatar
              v-else-if="item.data.image_encode"
              :src="`data:image/png;base64,${item.data.image_encode}`"
              :type="item.type_user"
            />
            <Avatar
              v-else
              src="/avatar/small/avatar.png"
              :type="item.type_user"
            />
          </template>

          <template #[`item.full_name`]="{ item }">
            <div>
              {{ item.full_name || $ml.get("users.unrecognized") }}
            </div>
            <small> {{ item.data.zone }} / {{ item.data.device_name }} </small>
          </template>

          <template #[`item.company`]="{ item }">
            <template v-if="item.organization && item.organization.length">
              {{ item.organization.map((item) => item.name).join(" / ") }}
            </template>
          </template>

          <template #[`item.created_at`]="{ item }">
            <div class="text-right">
              {{ item.created_at | moment("DD.MM.YY") }}
              <br />
              {{ item.created_at | moment("HH:mm:ss") }}
            </div>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataTable from "@/components/datatable";
import VuePagination from "@/components/pagination";
import Avatar from "@/components/Avatar";
import PassedFilter from "./PassedFilter";

export default {
  components: { DataTable, VuePagination, Avatar, PassedFilter },

  data() {
    const { page } = this.$route.query;

    return {
      isReportPending: false,

      fields: [
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          style: "width: 50px;",
          header: { style: "width: 91px" },
        },
        {
          name: "full_name",
          header: {
            text: this.$ml.get("users.visitor"),
          },
          sortBy: {
            name: "full_name",
          },
        },
        {
          name: "company",
          header: {
            text: this.$ml.get("users.subdivision"),
          },
        },
        {
          name: "created_at",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
          sortBy: {
            name: "created_at",
          },
        },
      ],

      data: [],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 10,
        current_page: page,
      },

      searchParams: [
        {
          value: "name",
          text: this.$ml.get("user.name"),
        },
        {
          value: "identety_value",
          text: this.$ml.get("advanced.identityValue"),
        },
        {
          value: "zone",
          text: this.$ml.get("advanced.zone"),
        },
      ],
      search: "",
      filter: {},

      // Sort
      sortName: "",
      sortOrder: "",
    };
  },

  methods: {
    getVisits() {
      const params = {
        page: this.pagination.current_page,
        ...this.filter,
      };

      if (this.search) {
        params.search = this.search;
      }

      if (this.sortName) {
        params.sortName = this.sortName;
        params.sortOrder = this.sortOrder || "asc";
      }

      this.axios.get("visit/log", { params }).then((response) => {
        const data = response.data.data ? response.data.data : [];
        this.pagination = response.data.meta.pagination || {};
        this.data = data.map((item) => ({
          ...item,
          data: item.event,
        }));
      });
    },

    onSearch(value) {
      this.search = value;
      this.pagination.current_page = 1;
      this.getVisits();
    },

    showUser(item) {
      if (item.user_id) {
        this.$router.push({ name: "showUser", params: { id: item.user_id } });
      }
    },

    styleRow(item) {
      if (item.blacklisted) {
        return "background: #272D3D; color: #fff;";
      } else if (item.temperatureValue >= this.tAlarm) {
        return "background: #ff9996; color: #000;";
      }
    },

    onChangeFilter(data) {
      this.filter = data;
      this.pagination.current_page = 1;
      this.getVisits();
    },

    reportWorkTime() {
      const params = {
        search: this.search,
        ...this.filter,
      };

      const requestParams = {
        method: "get",
        url: "report/worktime",
        responseType: "blob",
        params,
      };

      this.report(requestParams);
    },

    reportIOTime() {
      const params = {
        search: this.search,
        ...this.filter,
      };

      const requestParams = {
        method: "get",
        url: "report/iotime",
        responseType: "blob",
        params,
      };

      this.report(requestParams);
    },

    reportPassed() {
      const params = {
        search: this.search,
        ...this.filter,
      };

      const requestParams = {
        method: "get",
        url: "report/passed",
        responseType: "blob",
        params,
      };

      this.report(requestParams);
    },

    reportPassUsers() {
      const params = {
        search: this.search,
        ...this.filter,
      };

      const requestParams = {
        method: "get",
        url: "report/passusers",
        responseType: "blob",
        params,
      };

      this.report(requestParams);
    },

    reportPassedTemp() {
      const params = {
        search: this.search,
        istemp: true,
        ...this.filter,
      };

      const requestParams = {
        method: "get",
        url: "report/passed",
        responseType: "blob",
        params,
      };

      this.report(requestParams);
    },
    reportStat() {
      const params = {
        search: this.search,
        istemp: true,
        ...this.filter,
      };

      const requestParams = {
        method: "get",
        url: "report/stat",
        responseType: "blob",
        params,
      };

      this.report(requestParams);
    },
    report(requestParams) {
      this.isReportPending = true;

      this.axios
        .request(requestParams)
        .then((response) => {
          if (response.status === 204) {
            this.$notify({
              group: "info",
              type: "error",
              text: this.$ml.get("advanced.nopass"),
            });
          } else {
            const filename = `${requestParams.url}-${this.$moment().format(
              "YYYYMMDDHHmmss"
            )}.xlsx`;

            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/xlsx",
              })
            );

            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch(() => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("advanced.nopass"),
          });
        })
        .finally(() => {
          this.isReportPending = false;
        });
    },

    onSort({ name, order }) {
      this.sortName = name;
      this.sortOrder = order;

      this.getVisits();
    },
  },

  created() {
    this.getVisits();
  },
};
</script>
